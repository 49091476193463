import classes from './NoPasswordReset.module.scss';

import React, { useState } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import ResetPasswordMessage from '@lobox/uikit/ResetPasswordMessage';
import {
  Endpoints,
  forgetPassword as forgetPasswordApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import isFunction from 'lodash/isFunction';
import useResponseToast from '@shared/hooks/useResponseToast';
import type { FormProps } from '@lobox/uikit/Form';

export interface NoPasswordResetProps {
  className?: string;
  onSuccess?: FormProps['onSuccess'];
  onFailure?: FormProps['onFailure'];
}

const NoPasswordReset = ({
  className,
  onSuccess,
  onFailure,
}: NoPasswordResetProps): JSX.Element => {
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();
  const { authUser } = useGetAppObject();
  const email = authUser?.email?.value;

  const { handleSuccess: successToast } = useResponseToast();

  const handleSuccess: FormProps['onSuccess'] = (...args) => {
    setIsSent(true);

    if (isFunction(onSuccess)) {
      onSuccess(...args);
    }
  };

  const { mutate: forgetPassword } = useReactMutation({
    apiFunc: forgetPasswordApi,
  });
  const handleSendForgetPassword = () => {
    forgetPassword(
      { email },
      {
        onSuccess: () => {
          successToast({
            title: t('resend_recovery'),
            message: t('resend_recovery_success'),
          })();

          if (isFunction(onSuccess)) {
            onSuccess();
          }
        },
        onError: onFailure,
      }
    );
  };

  return isSent ? (
    <ResetPasswordMessage
      email={email}
      resendAction={handleSendForgetPassword}
    />
  ) : (
    <Form
      enableReinitialize
      initialValues={{ email }}
      onSuccess={handleSuccess}
      onFailure={onFailure}
      url={Endpoints.Auth.postForgetPassword}
    >
      {() => (
        <Flex className={cnj(classes.forceResetPassword, className)}>
          <Typography height={21} color="thirdText" mb={20}>
            {t('has_no_pass')}
          </Typography>
          <DynamicFormBuilder
            groups={[
              {
                name: 'email',
                cp: 'input',
                label: t('email'),
                // helperText: t('enter_y_mail_reg_lob'),
                disabled: true,
              },
            ]}
          />
          <Flex className={classes.buttonWrapper}>
            <SubmitButton
              active
              schema="primary-blue"
              labelFont="bold"
              label={t('create_password')}
            />
          </Flex>
        </Flex>
      )}
    </Form>
  );
};

export default NoPasswordReset;
