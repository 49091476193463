import classes from './ResetPassword.modal.module.scss';

import React, { useState } from 'react';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import Form from '@lobox/uikit/Form';
import ResetPasswordMessage from '@lobox/uikit/ResetPasswordMessage';
import {
  Endpoints,
  forgetPassword as forgetPasswordApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import isFunction from 'lodash/isFunction';
import useResponseToast from '@shared/hooks/useResponseToast';
import { errorMsg } from '@shared/constants/enums';
import Password from '@shared/svg/SvgPassword';
import {
  ModalBody,
  ModalDialog,
  ModalHeaderSimple,
  ModalBodyImage,
  ModalFooter,
} from '../../molecules';
import type { FormProps } from '@lobox/uikit/Form';

export interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSuccess?: FormProps['onSuccess'];
  onFailure?: FormProps['onFailure'];
}

const ResetPasswordModal = ({
  isOpen,
  onClose,
  onBack,
  onSuccess,
  onFailure,
}: ResetPasswordModalProps): JSX.Element => {
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();
  const { authUser } = useGetAppObject();
  const email = authUser?.email?.value;

  const { handleSuccess: successToast, handleError: errorToast } =
    useResponseToast();

  const handleSuccess: FormProps['onSuccess'] = (...args) => {
    setIsSent(true);

    if (isFunction(onSuccess)) {
      onSuccess(...args);
    }
  };
  const handleError: FormProps['onFailure'] = (error, ...args) => {
    if (isFunction(onFailure)) {
      onFailure(error, ...args);
    }
    const k = error?.response?.data?.error;
    const errorKey = errorMsg[k];

    errorToast({
      title: t('reset_password'),
      message: t(errorKey || 'un_predictable_error'),
    })();
  };

  const { mutate: forgetPassword } = useReactMutation({
    apiFunc: forgetPasswordApi,
  });
  const handleSendForgetPassword = () => {
    forgetPassword(
      { email },
      {
        onSuccess: () => {
          successToast({
            title: t('resend_recovery'),
            message: t('resend_recovery_success'),
          })();

          if (isFunction(onSuccess)) {
            onSuccess();
          }
        },
        onError: handleError,
      }
    );
  };

  return (
    <Form
      initialValues={{ email }}
      onSuccess={handleSuccess}
      url={Endpoints.Auth.postForgetPassword}
    >
      {() => (
        <ModalDialog
          isOpen={isOpen}
          onClose={onClose}
          onBack={onBack}
          variant="gradient"
          showConfirm={false}
        >
          <ModalHeaderSimple
            visibleHeaderDivider={false}
            title={t('reset_password')}
          />
          <ModalBody className={classes.modalBody}>
            <ModalBodyImage image={() => <Password />} />
            {isSent ? (
              <ResetPasswordMessage
                email={email}
                resendAction={handleSendForgetPassword}
              />
            ) : (
              <DynamicFormBuilder
                groups={[
                  {
                    name: 'email',
                    cp: 'input',
                    label: t('email'),
                    disabled: true,
                  },
                ]}
              />
            )}
          </ModalBody>
          {!isSent && (
            <ModalFooter className={classes.modalFooter}>
              <SubmitButton
                active
                schema="primary-blue"
                labelFont="bold"
                label={t('reset_password')}
              />
            </ModalFooter>
          )}
        </ModalDialog>
      )}
    </Form>
  );
};

export default ResetPasswordModal;
