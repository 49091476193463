import classes from './SelectUserAndRole.module.scss';

import React from 'react';
import { useFormikContext } from 'formik';
import isFunction from 'lodash/isFunction';
import AsyncAutoComplete from '@lobox/uikit/AutoComplete/AsyncAutoComplete';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import FieldWrapper from '@lobox/uikit/Form/FieldWrapper';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import { searchEndPoints, useTranslation } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import type { PaginateResponse } from '@lobox/utils';

type SelectedUserType = {
  id: string;
  title: string;
  image: string;
  username: string;
  isLock: boolean;
};

export interface SelectUserAndRoleProps {
  visibleHint?: boolean;
  roles: Array<{ label: string; value: string; helperText?: string }>;
  labels: {
    placeholder: string;
    submit: string;
  };
  onSelectUser?: (obj: SelectedUserType) => void;
  setDirtyStatus?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectUserAndRole = ({
  visibleHint,
  roles,
  labels,
  onSelectUser,
  setDirtyStatus,
}: SelectUserAndRoleProps): JSX.Element => {
  const { t } = useTranslation();
  const formContext = useFormikContext();
  const { businessPage } = useGetAppObject();

  React.useEffect(() => {
    if (setDirtyStatus) {
      setDirtyStatus(() => formContext.dirty);
    }
  }, [setDirtyStatus, formContext.dirty]);

  return (
    <FieldWrapper
      name="userAndRole"
      component={({ value, onChange }: any) => (
        <Flex className={classes.inputSelectWrapper}>
          <AsyncAutoComplete
            editable
            value={value?.user}
            onChange={(user: any) => {
              if (user?.value) {
                onChange({ ...value, user });

                if (isFunction(onSelectUser)) {
                  onSelectUser({
                    id: user.value,
                    image: user.image,
                    title: user.label,
                    username: user.username,
                    isPrivate: user.isPrivate,
                  });
                }
              }
            }}
            inputWrapClassName={classes.asyncInputWrap}
            className={classes.asyncWrapper}
            inputStyle={classes.asyncInputStyle}
            name="user"
            url={`${searchEndPoints.suggestObject}?includeUsername=true&userType=PERSON`}
            normalizer={(data: PaginateResponse<any>) =>
              data?.content?.reduce((prev: Array<any>, cur: any) => {
                if (cur.hideIt || cur.id === businessPage.ownerId) {
                  return prev;
                }
                return [
                  ...prev,
                  {
                    label: `${cur.name} ${cur.surname}`,
                    value: cur.id,
                    image: cur.croppedImageUrl,
                    job: cur.occupationName,
                    username: `@${cur.username}`,
                    isPrivate: !cur.allowPageRoleAssign,
                  },
                ];
              }, [])
            }
            placeholder={labels.placeholder}
            rightIcon={
              <Icon color="primaryText" size={15} type="far" name="search" />
            }
            renderItem={({ item }: any) => (
              <AvatarCard
                infoWrapClassName={classes.infoWrapperSelectUser}
                containerProps={{
                  className: classes.itemWrapper,
                }}
                data={{
                  title: item.label,
                  image: item.image,
                  subTitle: item.username,
                }}
                avatarProps={{ size: 'sm' }}
                titleProps={{
                  isTruncated: true,
                }}
                subTitleProps={{
                  isTruncated: true,
                }}
                action={
                  item?.isLock && (
                    <Tooltip
                      placement="left"
                      trigger={<Icon name="lock" type="fal" size={18} />}
                    >
                      {t('private')}
                    </Tooltip>
                  )
                }
              />
            )}
          />
          <DropdownSelect
            onChange={(role: any) => {
              onChange({ ...value, role });
            }}
            value={value?.role}
            options={roles}
            className={cnj(classes.dropDownSelect, classes.cursorPointer)}
            inputWrapClassName={classes.inputWrap}
            optionItemClassName={classes.optionItemClassName}
            visibleCheck={false}
            rightIconClassName={classes.rightIconWrapper}
            rightIconProps={{ size: 'sm', className: classes.rightIcon }}
            inputStyle={classes.inputStyle}
            withRightIconClassName={classes.withRightIcon}
          />
          <Typography
            size={13}
            height={15}
            color="inputPlaceholder"
            className={cnj(
              classes.hintText,
              visibleHint && classes.visibleHint
            )}
          >
            {visibleHint ? value?.role?.helperText : ''}
          </Typography>
          <SubmitButton className={classes.addBtn} label={labels.submit} />
        </Flex>
      )}
    />
  );
};

export default React.memo(SelectUserAndRole);
