import useToast from '@lobox/uikit/Toast/useToast';
import { useTranslation } from '@lobox/utils';

type HandlerArgsType = {
  title?: string;
  message?: string;
};

type HandlerToast = (args?: HandlerArgsType) => () => void;

type UseResponseToast = {
  handleSuccess: HandlerToast;
  handleError: HandlerToast;
};

const useResponseToast = (): UseResponseToast => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleSuccess =
    ({ title = t('success_updated'), message }: HandlerArgsType = {}) =>
    (): void => {
      toast({
        type: 'success',
        icon: 'check-circle',
        title,
        message,
      });
    };
  const handleError =
    ({ title = t('unknown_error'), message }: HandlerArgsType = {}) =>
    (): void => {
      toast({
        type: 'error',
        icon: 'times-circle',
        title,
        message,
      });
    };

  return {
    handleSuccess,
    handleError,
  };
};

export default useResponseToast;
