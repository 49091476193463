import classes from './SelectRole.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import  Divider from '@lobox/uikit/Divider/DividerVertical';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import FieldWrapper from '@lobox/uikit/Form/FieldWrapper';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import { useTranslation } from '@lobox/utils';

export interface SelectRoleProps {
  roles: Array<{ label: string; value: string; helperText?: string }>;
  onCancelSelect: () => void;
  onRemoveSelect: () => void;
}

const SelectRole = ({
  roles,
  onCancelSelect,
  onRemoveSelect,
}: SelectRoleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FieldWrapper
      name="userAndRole"
      component={({ value, onChange }: any) => (
        <Flex className={classes.selectRoleRoot}>
          <DropdownSelect
            className={classes.dropDown}
            inputWrapClassName={classes.inputWrap}
            rightIconClassName={classes.rightIconWrapper}
            rightIconProps={{ size: 'sm', className: classes.rightIcon }}
            inputStyle={classes.inputStyle}
            optionItemClassName={classes.optionItemClassName}
            onChange={(role: any) => {
              onChange({ ...value, role });
            }}
            visibleCheck={false}
            value={value?.role}
            options={roles}
          />
          <Flex className={classes.buttonsWrap}>
            <SubmitButton label={t('save')} className={classes.submitButton} />
            <Button
              label={t('cancel')}
              onClick={onCancelSelect}
              schema="semi-transparent"
            />
            <Divider height={32} className={classes.removeButtonDivider} />
            <IconButton
              colorSchema="semi-transparent"
              size="md15"
              name="trash"
              onClick={onRemoveSelect}
            />
          </Flex>
        </Flex>
      )}
    />
  );
};

export default SelectRole;
