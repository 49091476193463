import classes from './PageRoleUnblockModal.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import useMedia from '@lobox/uikit/utils/useMedia';
import AvatarCard from '@lobox/uikit/AvatarCard';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import {
  ModalBody,
  ModalDialog,
  ModalHeaderSimple,
  ModalFooter,
} from '../../molecules';

export interface PageRoleUnblockModalProps {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onBlock: () => void;
  blockedUser: { id: string; title: string; image: string; username: string };
}

const PageRoleUnblockModal = ({
  isOpen,
  onBack,
  onClose,
  onBlock,
  blockedUser,
}: PageRoleUnblockModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMoreThanTablet } = useMedia();

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      onBack={onBack}
      variant="gradient"
      showConfirm={false}
    >
      <ModalHeaderSimple title={t('page_role')} hideBack={isMoreThanTablet} />
      <ModalBody>
        <AvatarCard
          avatarProps={{ size: 'sm' }}
          containerProps={{ className: classes.avatarCardContainer }}
          data={{
            image: blockedUser?.image,
            title: blockedUser?.title,
            subTitle: blockedUser?.username,
            id: blockedUser?.id,
          }}
          titleProps={{ isWordWrap: true }}
          subTitleProps={{ isWordWrap: true }}
        />
        <Typography
          font="400"
          size={15}
          height={21}
          color="primaryText"
          mt={20}
        >
          {t('page_role_unblock')}
        </Typography>
      </ModalBody>
      <ModalFooter className={classes.footer}>
        <Button
          schema="secondary-light"
          label={t('cancel')}
          onClick={onClose}
        />
        <Button
          label={t('unblock')}
          className={classes.unblockButton}
          onClick={onBlock}
        />
      </ModalFooter>
    </ModalDialog>
  );
};

export default PageRoleUnblockModal;
