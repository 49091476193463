import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import ResetPasswordModalComponent from './ResetPassword.modal';
import type { ResetPasswordModalProps } from './ResetPassword.modal';

const ResetPasswordModal = (props: ResetPasswordModalProps): JSX.Element => (
  <ErrorBoundaryToast>
    <ResetPasswordModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default ResetPasswordModal;
