import classes from './ResetPasswordMessage.module.scss';

import React from 'react';
import { useTranslation } from '@lobox/utils';
import Typography from '../Typography';
import Flex from '../Flex';
import Icon from '../Icon';

export interface ResetPasswordMessageProps {
  email: string;
  resendAction: () => void;
}

const ResetPasswordMessage = ({
  email,
  resendAction,
}: ResetPasswordMessageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Flex className={classes.hintWrap}>
        <Icon
          type="far"
          name="envelope-open-text"
          size={20}
          color="graphene"
          className={classes.icon}
        />
        <Flex>
          <Typography font="700" size={16} height={28} color="primaryText">
            {t('check_your_email')}
          </Typography>
          <Typography mt={10} color="primaryText">
            {t('check_your_email_hint')}
          </Typography>
          <Typography
            color="primaryText"
            size={16}
            font="700"
            height={27}
            className={classes.enterTheCode}
          >
            {email}
          </Typography>
        </Flex>
      </Flex>
      <Flex className={classes.resendWrap}>
        <Typography>{t('didnt_receive')}</Typography>
        <Flex as="span" onClick={resendAction} className={classes.action}>
          <Typography color="brand" className={classes.clickToResend}>
            {t('click_resend_recovery')}
          </Typography>
        </Flex>
      </Flex>
    </>
  );
};

export default ResetPasswordMessage;
