import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import PageRoleUnblockModalComponent from './PageRoleUnblockModal';
import type { PageRoleUnblockModalProps } from './PageRoleUnblockModal';

const PageRoleUnblockModal = (
  props: PageRoleUnblockModalProps
): JSX.Element => (
  <ErrorBoundaryToast>
    <PageRoleUnblockModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default PageRoleUnblockModal;
