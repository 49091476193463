import { errorMsg } from '@shared/constants/enums';
import useResponseToast from '@shared/hooks/useResponseToast';
import type { FormikProps } from 'formik';

type ToastType = {
  title?: string;
  message?: string;
};

type UseConfirmPasswordFailureType = {
  handleConfirmPasswordFailure: (
    error: string,
    formRef: FormikProps<any>,
    toast?: ToastType
  ) => void;
};

const useConfirmPasswordFailure = (): UseConfirmPasswordFailureType => {
  const { handleError } = useResponseToast();

  const handleConfirmPasswordFailure = (
    error: string,
    formRef: FormikProps<any>,
    toast?: ToastType
  ): void => {
    if (error === errorMsg.WrongPasswordException) {
      formRef.setStatus({
        password: errorMsg.WrongPasswordException,
      });
      formRef.setSubmitting(false);
    } else {
      handleError(toast)();
    }
  };

  return {
    handleConfirmPasswordFailure,
  };
};

export default useConfirmPasswordFailure;
