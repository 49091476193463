import classes from './ConfirmPassword.modal.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useAuthUser, useDisclosure, useTranslation } from '@lobox/utils';
import useResponseToast from '@shared/hooks/useResponseToast';
import Password from '@shared/svg/SvgPassword';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import ResetPasswordModal from '@shared/components/Organism/ResetPasswordModal';
import type { FormProps } from '@lobox/uikit/Form';
import {
  ModalBody,
  ModalBodyImage,
  ModalDialog,
  ModalFooter,
  ModalHeaderSimple,
} from '../../molecules';
import NoPasswordReset from '../NoPasswordReset';

export interface ConfirmPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  formProps?: Omit<FormProps, 'initialValues' | 'children'>;
}

const ConfirmPasswordModal = ({
  isOpen,
  onClose,
  onBack,
  formProps,
}: ConfirmPasswordModalProps): JSX.Element => {
  const { data: user } = useAuthUser();
  const { t } = useTranslation();
  const { isMoreThanTablet } = useMedia();
  const { handleError, handleSuccess } = useResponseToast();
  const { getAppObjectPropValue, isBusinessApp } = useGetAppObject();
  const {
    isOpen: visibleResetPassModal,
    onOpen: openResetPasswordModal,
    onClose: closeResetPassModal,
  } = useDisclosure();

  const hasNoPassword = !user?.hasPassword;

  const openResetPasswordModalHandler = () => {
    onClose();
    openResetPasswordModal();
  };
  const onSuccessResetPassword = () => {
    onClose();
    handleSuccess({
      title: null,
      message: t('email_sent'),
    })();
  };

  return (
    <>
      <Form initialValues={{ password: null }} {...formProps}>
        {() => (
          <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            onBack={onBack}
            variant="gradient"
            showConfirm={false}
          >
            <ModalHeaderSimple
              title={t('confirm_pass')}
              hideBack={isMoreThanTablet}
            />
            <ModalBody className={classes.modalBody}>
              <ModalBodyImage image={() => <Password />} />
              <AvatarCard
                noHover
                infoWrapClassName={classes.infoWrapConfirmModified2}
                avatarProps={{ isCompany: isBusinessApp, size: 'sm' }}
                titleProps={{ isTruncated: true, isWordWrap: true }}
                subTitleProps={{ isTruncated: true, isWordWrap: true }}
                titleHelperProps={{ className: classes.helper }}
                containerProps={{
                  className: cnj(
                    classes.avatarCardRoot,
                    hasNoPassword && classes.marginBottom
                  ),
                }}
                data={{
                  image: getAppObjectPropValue({
                    userKey: 'croppedImageUrl',
                    pageKey: 'croppedImageUrl',
                  }),
                  title: getAppObjectPropValue({
                    pageKey: 'title',
                    userKey: 'fullName',
                  }),
                  subTitle: getAppObjectPropValue({
                    userKey: 'usernameAtSign',
                    pageKey: 'usernameAtSign',
                  }),
                }}
              />
              {hasNoPassword ? (
                <NoPasswordReset
                  onFailure={({ response }) => {
                    handleError({
                      title: t('create_password'),
                      message: t(
                        response?.data?.error || 'un_predictable_error'
                      ),
                    })();
                  }}
                />
              ) : (
                <Typography
                  height={21}
                  color="thirdText"
                  className={classes.hint}
                >
                  {t('for_y_sec_re_enter')}
                </Typography>
              )}
              {!hasNoPassword && (
                <DynamicFormBuilder
                  groups={[
                    {
                      name: 'password',
                      cp: 'input',
                      label: t('password'),
                      type: 'password',
                      keyboardType: 'visible-password',
                    },
                  ]}
                />
              )}
            </ModalBody>
            {!hasNoPassword && (
              <ModalFooter className={classes.modalFooter}>
                <BaseButton onClick={openResetPasswordModalHandler}>
                  <Typography color="brand">{t('forget_password')}</Typography>
                </BaseButton>
                <SubmitButton
                  active
                  className={classes.resetButton}
                  schema="primary-blue"
                  labelFont="bold"
                  label={t('confirm')}
                />
              </ModalFooter>
            )}
          </ModalDialog>
        )}
      </Form>
      {visibleResetPassModal && (
        <ResetPasswordModal
          isOpen={visibleResetPassModal}
          onClose={closeResetPassModal}
          onBack={closeResetPassModal}
          onFailure={closeResetPassModal}
          onSuccess={onSuccessResetPassword}
        />
      )}
    </>
  );
};

export default ConfirmPasswordModal;
