import classes from './AssignPageRole.component.module.scss';

import React, { Fragment, useRef, useState } from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Form from '@lobox/uikit/Form';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useToast from '@lobox/uikit/Toast/useToast';
import {
  APP_ENTITIES,
  deletePageMember,
  formValidator,
  givePageAccess,
  PAGE_MEMBER_STATUS,
  PAGE_ROLES,
  preventClickHandler,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import useGetPageMembers from '@shared/hooks/api-hook/useGetPageMembers';
import useResponseToast from '@shared/hooks/useResponseToast';
import { errorMsg } from '@shared/constants/enums';
import PageRoleUnblockModal from '@shared/components/Organism/PageRoleUnblockModal';
import useUnblock from '@shared/hooks/api-hook/useUnblock';
import useConfirmPasswordFailure from '@shared/hooks/useConfirmPasswordFailure';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import { useHasPermission } from '@shared/hooks';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import ConfirmPasswordModal from '../ConfirmPasswordModal';
import SelectUserAndRole, {
  SelectRole,
} from '../../molecules/SelectUserAndRole';

type SelectedUserType = {
  id: string;
  title: string;
  image: string;
  username: string;
  isPrivate?: boolean;
};

export interface AssignPageRoleProps {
  setPageRoleModalVisibility?: React.Dispatch<React.SetStateAction<boolean>>;
  setDirtyStatus?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignPageRole = ({
  setPageRoleModalVisibility,
  setDirtyStatus,
}: AssignPageRoleProps): JSX.Element => {
  const userAndRoleRef = useRef<any>(null);
  const isDeleteRef = useRef<any>(false);
  const confirmPassFormRef = useRef<any>(null);
  const [selected, setSelected] = useState<SelectedUserType>(null);
  const handleCancelSelect = () => setSelected(null);
  const { t } = useTranslation();
  const { handleError } = useResponseToast();
  const { businessPage, checkIsBusinessOwner, checkIsAuthUser } =
    useGetAppObject();
  const casAssignRole = useHasPermission([SCOPES.casAssignRole]);

  const roles = React.useMemo(
    () => [
      {
        label: 'Admin',
        value: 'ADMIN',
        helperText: t('admin_have_ful_c_post'),
      },
      {
        label: 'Editor',
        value: 'EDITOR',
        helperText: t('editor_c_p_post_r_comm'),
      },
      {
        label: 'Recruiter',
        value: 'RECRUITER',
        helperText: t('recruiters_c_c_job_post'),
      },
      {
        label: 'Expert',
        value: 'EXPERT',
        helperText: t('experts_c_c_test_m_elevation'),
      },
    ],
    []
  );
  const labels = React.useMemo(
    () => ({
      placeholder: t('type_name_email'),
      submit: t('add'),
    }),
    []
  );

  const roleFormRef = useRef<any>(null);
  const [visibleConfirmPassModal, setVisibleConfirmPassModal] = useState(false);
  const [visibleUnblockModal, setVisibleUnblockModal] = useState(false);
  const [passwd, setPasswd] = useState('');
  const toast = useToast();
  const pageId = businessPage?.id;

  const openConfirmPasswordModal = () => {
    setVisibleConfirmPassModal(true);

    if (isFunction(setPageRoleModalVisibility)) {
      setPageRoleModalVisibility(() => false);
    }
  };

  const {
    data: people = [],
    refetch,
    isFetching,
  } = useGetPageMembers({
    enabled: true,
  });

  const { mutate: putPageAccess } = useReactMutation({
    apiFunc: givePageAccess,
  });

  const { mutate: deletePageAccess } = useReactMutation({
    apiFunc: deletePageMember,
  });

  const { handleConfirmPasswordFailure } = useConfirmPasswordFailure();

  const { unblock } = useUnblock();

  const handleUnblock = () => {
    unblock(
      {
        entityType: APP_ENTITIES.person,
        entityId: selected.id,
      },
      {
        onSuccess: () => {
          postNewRoleHandler(passwd);
          onCloseUnblockModal();
        },
        onError: handleError(),
      }
    );
  };

  const onSuccessHandler = () => {
    refetch();
    setVisibleConfirmPassModal(false);
    handleCancelSelect();
    roleFormRef.current.resetForm();
    isDeleteRef.current = false;
    userAndRoleRef.current = null;
    if (isFunction(setPageRoleModalVisibility)) {
      setPageRoleModalVisibility(() => true);
    }
  };

  const postNewRoleHandler = (password?: string) => {
    putPageAccess(
      {
        pageId,
        credentials: password ? { password } : undefined,
        ...userAndRoleRef.current,
      },
      {
        onSuccess: onSuccessHandler,
        onError: ({ response }: any) => {
          // @ts-ignore
          const errorKey = errorMsg[response?.data?.error];

          if (roleFormRef.current) {
            roleFormRef.current?.setSubmitting(false);
          }
          if (confirmPassFormRef?.current) {
            confirmPassFormRef.current.setSubmitting(false);
          }

          if (errorKey === errorMsg.UserIsBlockedException) {
            onOpenUnblockModal(password);
          } else {
            handleConfirmPasswordFailure(errorKey, confirmPassFormRef.current, {
              title: null,
              message: t(errorKey),
            });
          }
        },
      }
    );
  };

  const deleteRoleHandler = (password?: string) => {
    const { userId, role } = userAndRoleRef.current;

    deletePageAccess(
      {
        pageId,
        credentials: password ? { password } : undefined,
        userId,
        role,
      },
      {
        onSuccess: onSuccessHandler,
        onError: () => {
          confirmPassFormRef.current.setSubmitting(false);
          confirmPassFormRef.current.setStatus({ password: 'wrong_password' });
        },
      }
    );
  };

  const postNewRole = ({ user, newRole }, formRef: any) => {
    roleFormRef.current = formRef;
    userAndRoleRef.current = {
      userId: user.value,
      user,
      newRole: newRole.value,
      oldRole: selected.role,
    };
    if (selected?.isPrivate) {
      toast({
        message: `${t('role_private')} ${selected.title}`,
      });
      formRef.resetForm();
    } else if (newRole.value === 'ADMIN') {
      openConfirmPasswordModal();
    } else {
      postNewRoleHandler();
    }
  };

  const onDeleteHandler = ({ user, role }: any) => {
    userAndRoleRef.current = {
      userId: user?.id,
      pageId,
      user,
      role,
    };
    isDeleteRef.current = true;
    if (role === 'ADMIN') {
      openConfirmPasswordModal();
    } else {
      deleteRoleHandler();
    }
  };

  const onCloseConfirmPasswordModal = () => {
    setVisibleConfirmPassModal(false);
    roleFormRef.current?.setSubmitting(false);
    isDeleteRef.current = false;
    if (isFunction(setPageRoleModalVisibility)) {
      setPageRoleModalVisibility(() => true);
    }
  };

  const onCloseUnblockModal = () => {
    setVisibleUnblockModal(false);
    // handleCancelSelect();
    if (isFunction(setPageRoleModalVisibility)) {
      setPageRoleModalVisibility(() => true);
    }
  };

  const onOpenUnblockModal = (password?: string) => {
    setPasswd(password);
    setVisibleUnblockModal(true);
    setVisibleConfirmPassModal(false);

    if (isFunction(setPageRoleModalVisibility)) {
      setPageRoleModalVisibility(() => false);
    }
  };

  const isEditable = ({ user }: any): boolean =>
    !(checkIsAuthUser(user?.id) || checkIsBusinessOwner(user?.id)) &&
    casAssignRole;

  const { openConfirmDialog } = useOpenConfirm();

  const openConfirm =
    ({ user, role }: any) =>
    () =>
      openConfirmDialog({
        title: t('remove_page_role'),
        message: t('r_y_s_w_r_role'),
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        confirmCallback: () => onDeleteHandler({ user, role }),
      });

  const groupedPeople = React.useMemo(
    () => groupBy(people, 'role'),
    [people, isFetching]
  );
  const sortedPeople = React.useMemo(
    () =>
      reduce(
        roles,
        (acc, { value }) => [...acc, ...(groupedPeople[value] || [])],
        []
      ),
    [roles, groupedPeople, isFetching]
  );

  return (
    <Flex>
      {casAssignRole && (
        <Form
          initialValues={{
            userAndRole: {
              user: null,
              role: roles[0],
            },
          }}
          validationSchema={formValidator.object().shape({
            userAndRole: formValidator
              .object()
              .test(
                'user',
                'select_one_of_sug_users',
                (val) => val?.user?.value
              ),
          })}
          local
          enableReinitialize={false}
          onSuccess={({ userAndRole }, formRef) =>
            postNewRole(
              {
                user: userAndRole.user,
                newRole: userAndRole.role,
              },
              formRef
            )
          }
          innerRef={roleFormRef}
        >
          {() => (
            <SelectUserAndRole
              visibleHint
              roles={roles}
              labels={labels}
              onSelectUser={setSelected}
              setDirtyStatus={setDirtyStatus}
            />
          )}
        </Form>
      )}
      <Flex
        className={cnj(
          classes.listWrapper,
          casAssignRole && classes.casAssignRoleList
        )}
      >
        {map(sortedPeople, ({ status, role, user, id }, index) => {
          const isSelected = selected?.id === id;
          const userRole = roles.find((i) => i.value === role);

          return (
            <Fragment key={id + role}>
              {index !== 0 && <Divider />}
              <Flex className={classes.cardWrapper}>
                <AvatarCard
                  infoWrapClassName={classes.infoWrapModified}
                  containerProps={{ className: classes.avatarCard }}
                  helperDividerClassName={classes.roleDivider}
                  onClick={handleCancelSelect}
                  noHover
                  key={id}
                  data={{
                    image: user.image,
                    title: user.fullName,
                    subTitle: user.username,
                    titleHelper: checkIsBusinessOwner(user?.id)
                      ? t(PAGE_ROLES.OWNER.label)
                      : t(PAGE_ROLES[role].label),
                    // id: setPageRoleModalVisibility ? undefined : user.id,
                  }}
                  avatarProps={{
                    className: cnj(
                      !setPageRoleModalVisibility && classes.hover
                    ),
                  }}
                  // @ts-ignore
                  titleProps={{
                    height: 20,
                    isTruncated: true,
                    className: cnj(
                      classes.title,
                      !setPageRoleModalVisibility && classes.hover
                    ),
                  }}
                  // @ts-ignore
                  subTitleProps={{
                    className: cnj(
                      !setPageRoleModalVisibility && classes.hover,
                      classes.usernameAtSign
                    ),
                    color: 'primaryText',
                    height: 21,
                    size: 15,
                    isTruncated: true,
                  }}
                  action={
                    isEditable({ user, role, id, status }) &&
                    !isSelected && (
                      <IconButton
                        name="pen"
                        size="md15"
                        colorSchema="semi-transparent"
                        className={classes.editButton}
                        onClick={(e: any) => {
                          preventClickHandler(e);
                          setSelected({
                            role,
                            id,
                            title: user.fullName,
                            image: user.image,
                            username: user.username,
                          });
                        }}
                      />
                    )
                  }
                />
                {isSelected ? (
                  <Form
                    initialValues={{
                      userAndRole: {
                        role: userRole,
                        user: { ...user, value: user.id, label: user.fullName },
                      },
                    }}
                    onSuccess={({ userAndRole }, formRef) =>
                      postNewRole(
                        {
                          user: userAndRole.user,
                          newRole: userAndRole.role,
                        },
                        formRef
                      )
                    }
                    local
                  >
                    {() => (
                      <SelectRole
                        roles={roles}
                        onCancelSelect={handleCancelSelect}
                        onRemoveSelect={openConfirm({ user, role })}
                      />
                    )}
                  </Form>
                ) : (
                  <Flex className={classes.statusWrapper}>
                    {status === PAGE_MEMBER_STATUS.PENDING && (
                      <Typography color="pendingOrange">
                        {t('request_sent')}
                      </Typography>
                    )}
                    {status === PAGE_MEMBER_STATUS.DECLINED && (
                      <Typography color="error">
                        {t('request_declined')}
                      </Typography>
                    )}
                  </Flex>
                )}
              </Flex>
            </Fragment>
          );
        })}
      </Flex>
      <ConfirmPasswordModal
        isOpen={visibleConfirmPassModal}
        onClose={onCloseConfirmPasswordModal}
        onBack={onCloseConfirmPasswordModal}
        formProps={{
          local: true,
          onSuccess: ({ password }: any, formRef: any) => {
            confirmPassFormRef.current = formRef;
            if (isDeleteRef.current) {
              deleteRoleHandler(password);
            } else {
              postNewRoleHandler(password);
            }
          },
        }}
      />
      {visibleUnblockModal && (
        <PageRoleUnblockModal
          isOpen={visibleUnblockModal}
          onBack={onCloseUnblockModal}
          onClose={onCloseUnblockModal}
          onBlock={handleUnblock}
          blockedUser={selected}
        />
      )}
    </Flex>
  );
};

export default AssignPageRole;
