import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgPassword(props: any) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={290}
      height={192}
      viewBox="0 0 290 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.657 189.915s-25.509-41.068 2.405-85.529c24.76-39.442 63.842-8.072 85.978-48.211 30.199-54.741 180.877-33.367 161.795 50.852-9.231 40.752 41.916 42.242 22.885 82.973l-273.063-.085z"
        fill="url(#svg_password0_linear)"
      />
      <g opacity={0.5}>
        <path
          d="M173 65.12h-34V44.841c0-1.567 1.304-2.841 2.909-2.841h12.837c.301 0 .602.098.903.294l3.109 2.547c.301.196.602.294.903.294h10.43c1.605 0 2.909 1.273 2.909 2.84V65.12z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M168.648 62.4h-25.305c-.955 0-1.623-.801-1.623-1.702V47.782c0-1.001.764-1.702 1.623-1.702h25.305c.955 0 1.624.8 1.624 1.702v12.916c.095.901-.669 1.702-1.624 1.702z"
          fill="#fff"
        />
        <path
          d="M169.69 70.56h-27.38c-1.806 0-3.31-1.43-3.31-3.146V53.306c0-1.716 1.504-3.146 3.31-3.146h27.38c1.806 0 3.31 1.43 3.31 3.146V67.51c0 1.715-1.504 3.05-3.31 3.05z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M161.346 56.96h-.757V55.6c0-2.234-2.056-4.08-4.543-4.08-2.488 0-4.544 1.846-4.544 4.08v1.36h-.757c-.541 0-.865.389-.865.777v6.412c0 1.36 1.19 2.331 2.596 2.331h7.031c1.514 0 2.596-1.069 2.596-2.331v-6.412c.108-.388-.324-.777-.757-.777zm-8.221-1.36c0-1.457 1.298-2.623 2.921-2.623 1.622 0 2.92 1.166 2.92 2.623v1.36h-5.841V55.6z"
          fill="#485363"
          fillOpacity={0.6}
        />
        <path
          d="M156.68 61.018c0-.714-.635-1.338-1.36-1.338-.725 0-1.36.624-1.36 1.338 0 .535.363.98.816 1.16v2.407c0 .268.272.535.544.535s.544-.267.544-.535v-2.408c.544-.178.816-.624.816-1.16z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
      </g>
      <path
        opacity={0.5}
        d="M222 190H90a4 4 0 01-4-4V85.85c0-4.36 3.472-7.85 7.811-7.85h120.291c4.34 0 7.811 3.49 7.811 7.85v104.063c0 .048.039.087.087.087z"
        fill="#fff"
        fillOpacity={0.1}
      />
      <path
        opacity={0.1}
        d="M139.375 181.539H97.716a1.838 1.838 0 01-1.822-1.832v-19.015c0-.96.78-1.832 1.822-1.832h41.746c.955 0 1.823.785 1.823 1.832v19.015c-.087.96-.868 1.832-1.91 1.832zM175.914 99.196H96.935c-.52 0-1.041-.436-1.041-1.046v-2.007c0-.523.433-1.046 1.041-1.046h79.066c.521 0 1.041.436 1.041 1.046v2.007c-.087.61-.52 1.046-1.128 1.046zM175.914 107.57H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.523.433-1.047 1.041-1.047h79.066c.521 0 1.041.437 1.041 1.047v2.006c-.087.611-.52 1.047-1.128 1.047zM175.914 115.944H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.523.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.611-.52 1.047-1.128 1.047zM175.914 124.318H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.093a1.13 1.13 0 01-1.128.96zM175.914 132.692H96.935c-.52 0-1.041-.437-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.61-.52 1.047-1.128 1.047zM175.914 141.066H96.935c-.52 0-1.041-.437-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.61-.52 1.047-1.128 1.047z"
        fill="#F1F4F9"
      />
      <path
        opacity={0.5}
        d="M176.854 177.802a.689.689 0 011.246 0 .689.689 0 00.525.388l.021.003a.727.727 0 01.412 1.233l-.021.021a.737.737 0 00-.207.623l.011.073a.7.7 0 01-1.033.709.698.698 0 00-.668-.007.716.716 0 01-1.036-.766.718.718 0 00-.195-.639l-.014-.014a.727.727 0 01.413-1.233l.021-.003a.69.69 0 00.525-.388zM184.838 177.802a.689.689 0 011.246 0 .69.69 0 00.525.388l.021.003a.727.727 0 01.412 1.233l-.013.014a.717.717 0 00-.196.638.715.715 0 01-1.037.766.714.714 0 00-.669 0 .716.716 0 01-1.038-.766.714.714 0 00-.196-.638l-.013-.014a.727.727 0 01.412-1.233l.021-.003a.69.69 0 00.525-.388zM192.829 177.787a.719.719 0 011.287-.027l.031.059c.105.2.298.338.521.372a.707.707 0 01.396 1.198l-.051.051a.718.718 0 00-.195.639.716.716 0 01-1.036.766.698.698 0 00-.668.007.698.698 0 01-1.032-.709l.01-.073a.734.734 0 00-.207-.623l-.021-.021a.73.73 0 01.416-1.234.719.719 0 00.549-.405zM200.847 177.76a.719.719 0 011.287.027c.103.219.31.371.549.405a.73.73 0 01.416 1.234l-.021.021a.737.737 0 00-.207.623l.011.073a.7.7 0 01-1.033.709.698.698 0 00-.668-.007.716.716 0 01-1.036-.766.718.718 0 00-.195-.639l-.051-.051a.707.707 0 01.396-1.198.707.707 0 00.521-.372l.031-.059zM208.879 177.802a.689.689 0 011.246 0 .69.69 0 00.525.388l.021.003a.727.727 0 01.412 1.233l-.021.021a.737.737 0 00-.206.623l.01.073a.699.699 0 01-1.032.709.7.7 0 00-.669-.007.716.716 0 01-1.035-.766.716.716 0 00-.196-.639l-.013-.014a.727.727 0 01.412-1.233l.021-.003a.69.69 0 00.525-.388z"
        fill="#fff"
        fillOpacity={0.1}
      />
      <path
        d="M169.925 112.368c-9.633-3.228-14.754-9.159-14.754-9.159s-5.207 5.931-14.754 9.159c-8.766 2.878-17.966 2.529-17.966 2.529s0 31.489 10.762 44.137c10.068 11.863 21.871 13.957 21.871 13.957s11.804-2.094 21.871-13.957c10.762-12.648 10.762-44.137 10.762-44.137s-9.199.349-17.792-2.529z"
        fill="url(#svg_password1_linear)"
      />
      <path
        opacity={0.75}
        d="M154.043 168.019c-2.69-.698-10.935-3.577-18.225-12.125-6.597-7.85-8.853-25.819-9.461-36.199 3.819-.262 9.2-.873 14.408-2.617 5.988-2.006 10.414-4.885 13.278-7.153 2.865 2.268 7.291 5.234 13.279 7.153 5.208 1.744 10.589 2.442 14.407 2.617-.52 10.38-2.777 28.349-9.46 36.199-7.29 8.548-15.622 11.427-18.226 12.125z"
        fill="#83B4FF"
      />
      <path
        d="M154.044 166.361c-2.864-.785-10.328-3.576-17.011-11.514-6.075-7.152-8.332-23.464-8.939-33.757 3.732-.349 8.505-1.046 13.192-2.617 5.554-1.831 9.807-4.448 12.758-6.716a47.633 47.633 0 0012.758 6.716c4.687 1.571 9.46 2.268 13.192 2.617-.607 10.293-2.864 26.605-8.939 33.757-6.77 7.938-14.234 10.729-17.011 11.514z"
        fill="url(#svg_password2_linear)"
      />
      <path
        d="M159.511 132.517c0 3.053-2.43 5.495-5.468 5.495s-5.468-2.442-5.468-5.495 2.43-5.495 5.468-5.495c2.951 0 5.468 2.442 5.468 5.495z"
        fill="#fff"
      />
      <path
        d="M154.043 149.614a2.34 2.34 0 01-2.344-2.356v-12.647a2.34 2.34 0 012.344-2.356 2.34 2.34 0 012.343 2.356v12.735c-.087 1.221-1.128 2.268-2.343 2.268z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M168.798 112.368c-9.633-3.228-14.754-9.159-14.754-9.159v69.782s11.803-2.094 21.871-13.957c10.762-12.648 10.762-44.137 10.762-44.137s-9.287.349-17.879-2.529z"
        fill="#83B4FF"
      />
      <path
        opacity={0.1}
        d="M214.189 78H93.811C89.471 78 86 81.49 86 85.85v3.751h136v-3.75c0-4.362-3.472-7.851-7.811-7.851z"
        fill="#F1F4F9"
      />
      <path
        d="M201.864 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221zM208.114 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221zM214.362 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221z"
        fill="#fff"
      />
      <path
        d="M58.617 89.719c2.228 2.588 3.986 8.941 6.214 11.529 1.055 1.295 9.027 8.236 14.42 11.177.235-1.647 3.634-.588 2.462-1.647-3.751-3.412-10.2-6.824-12.427-13.06-1.407-4.117-6.8-13.176-6.8-13.176"
        fill="url(#svg_password3_linear)"
      />
      <path
        d="M104.222 107.719H64.479V83.365a3.408 3.408 0 013.4-3.411h15.006c.352 0 .703.117 1.055.352l3.634 3.06c.352.235.704.352 1.056.352h12.192c1.876 0 3.4 1.53 3.4 3.412v20.589z"
        fill="url(#svg_password4_linear)"
      />
      <path
        d="M100.12 104.307H69.052c-1.173 0-1.993-.941-1.993-2V87.13c0-1.176.938-2 1.993-2h31.068c1.172 0 1.993.941 1.993 2v15.177c.117 1.059-.821 2-1.993 2z"
        fill="#fff"
      />
      <path
        d="M100.353 114.19H68.347c-2.11 0-3.868-1.765-3.868-3.882V92.895c0-2.117 1.758-3.882 3.868-3.882h32.006c2.111 0 3.869 1.765 3.869 3.882v17.53c0 2.118-1.758 3.765-3.869 3.765z"
        fill="url(#svg_password5_linear)"
      />
      <path
        d="M90.154 97.954h-.82v-1.647c0-2.706-2.228-4.941-4.925-4.941-2.696 0-4.924 2.235-4.924 4.941v1.647h-.82c-.587 0-.938.47-.938.942v7.765c0 1.647 1.29 2.823 2.813 2.823h7.62c1.642 0 2.815-1.294 2.815-2.823v-7.766c.117-.47-.352-.94-.821-.94zm-8.91-1.647a3.158 3.158 0 013.165-3.176 3.158 3.158 0 013.166 3.176v1.647h-6.331v-1.647z"
        fill="#fff"
      />
      <path
        d="M86.051 101.954c0-.941-.82-1.764-1.758-1.764-.938 0-1.759.823-1.759 1.764 0 .706.47 1.295 1.055 1.53v3.177c0 .352.352.705.704.705.351 0 .703-.353.703-.705v-3.177c.704-.235 1.055-.824 1.055-1.53z"
        fill="url(#svg_password6_linear)"
      />
      <path
        d="M54.28 124.308l-4.221 10c1.875 3.059 2.93 6.707 3.165 10.471.117 1.883 0 3.883 0 5.765 0 .118 0 .236.117.471.587-2.824.938-5.177 1.055-6.706.47-4.353.352-11.177 0-19.883 0-.118 0-.118-.117-.118z"
        fill="#DB856E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.08 103.954l10.082 8.354.161 4.957c.403 12.265.71 21.62.191 26.926-.277 2.89-1.444 8.643-2.77 15.186-1.205 5.939-2.541 12.53-3.465 18.222l.021.005s-.133.725-.277 1.622c-.079.521-.154 1.033-.225 1.535-.11.861-.172 1.607-.084 1.785.234.588 1.172 2.235 2.11 2.706.481.301 1.301.634 2.002.918.666.27 1.224.496 1.28.611.118.235.235.706-.116.941-.821.589-2.11.942-3.049.824-1.584-.255-2.38-.75-3.056-1.17-.573-.356-1.06-.659-1.867-.713-.06 0-.14.023-.24.05-.303.083-.786.215-1.402-.05-.82-.353-1.172-1.176-1.172-1.882.093-.931.552-1.788.972-2.571.111-.207.22-.409.317-.606.47-.941.704-5.059.704-5.059l.107.028c.12-2.189.158-5.612.197-9.228.061-5.597.127-11.659.517-14.33.377-2.571 1.14-5.094 1.701-6.943.311-1.028.56-1.847.643-2.351.235-1.295-3.048-20.119-3.517-21.413-.187-.563-.467-1.145-.796-1.829-.496-1.028-1.102-2.286-1.666-4.054-1.875-6.117 2.697-12.471 2.697-12.471z"
        fill="url(#svg_password7_linear)"
      />
      <path
        d="M53.224 187.958c.235-.353.235-.942 0-1.059-.234-.236-1.29-.824-1.875-1.059-.586-.235-1.407-.471-1.173-.353.352.118-.234.823-.82.823-1.876-.117-3.166-1.294-4.221-1.529-1.524-.353-2.11-2.118-2.227-2.353-.235.235-.938 1.647-1.056 2.118-.117.353-.351 1.176-.234 1.529.117.235.234.824.234 1.53 0 0 .352.235 1.056.353.468.117 1.172 0 1.172 0l.117-.942s.352 0 .938.118c.469.118 1.407.588 1.407.588.234.236.352.706.703.824.704.353 1.407.47 2.345.588 2.11.118 3.283-.471 3.634-1.176z"
        fill="#112B46"
      />
      <path
        d="M67.996 187.252c-.234-.236-2.579-.589-3.634-1.059-1.055-.471-2.227-1.883-2.58-2.471-.234-.588-.234-3.529-.116-4.824.234-8.706 1.758-29.765 1.993-34.824.351-8.824-.704-17.883-4.104-32.002l-12.544-9.647s-3.517 6.589-2.345 12.824c.703 3.647 3.752 4.236 4.22 5.647.47 1.412 8.09 24.237 8.09 24.237s-1.524 4.941-1.524 10.353c-.117 4.588 1.993 18.589 2.345 23.883 0 1.059.234 3.53-.118 4.353-.351.941-1.29 2.118-1.29 3.294 0 .706.587 1.412 1.408 1.648.938.235 1.407-.236 1.641-.236 1.759-.117 2.227 1.177 5.276 1.177.938 0 2.344-.471 3.048-1.294.469-.471.469-.824.234-1.059z"
        fill="url(#svg_password8_linear)"
      />
      <path
        d="M68.231 188.311c.118-.353.118-.942-.117-1.059-.352-.118-1.407-.589-1.993-.824-.586-.117-1.524-.235-1.172-.117.351.117-.117.823-.704.941-1.876.235-3.4-.824-4.572-.824-1.641-.117-2.227-1.765-2.345-2-.234.235-1.055 1.765-1.172 2.235-.117.471-.235 1.177 0 1.53.117.235.352.824.352 1.412 0 0 .351.117 1.172.235.469 0 1.172-.118 1.172-.118v-.941s.47-.117.938-.117c.587 0 1.173.353 1.524.588.235.235.587.47.821.588.938.235 1.524.235 2.462.235 2.228-.353 3.4-1.058 3.634-1.764z"
        fill="#112B46"
      />
      <path
        d="M44.431 103.366c-3.165 3.294-5.275 8.118-4.103 13.412.352 1.412 2.11 5.177 2.345 6.589.82 3.177 2.227 7.765 2.462 11.53 4.924 2.941 14.42 1.882 18.523-1.059.118-4.471-.351-8-1.172-14.118-1.29-9.177-2.814-11.295-4.22-14.942-.118-.706-.118-2-.352-2.706"
        fill="url(#svg_password9_linear)"
      />
      <path
        d="M57.914 73.13c.82.235.469 2.118 1.29 2.353.586.235 1.641-.118 2.227.235 2.814 1.53 2.11 4.706 2.345 8.589 1.172 2.94 1.524 5.647.586 7.412-1.172 2.117-2.345 2.706-3.869 4.353-1.055 1.294-1.407 3.882-1.641 6.706 0 .471.352 1.294.234 1.765-.234.941-1.64 1.176-3.165 1.176-3.517.118-8.91-1.176-10.434-1.176-1.524 0-2.931-.706-2.697-1.53.118-.588.704-.823.938-2 .587-2.588.938-9.177 1.407-11.412 1.055-5.647 1.876-9.883 4.22-15.177"
        fill="url(#svg_password10_linear)"
      />
      <path
        d="M66.004 86.777c-.821-1.529-1.993-2.94-3.166-4.235l-3.048 1.412c1.407 2.706 1.641 6 2.345 8.941.703.118 1.758-.117 1.758-.117.352-.47.235-.236.587-.942.468-1.176 1.29-3.058 1.524-5.059z"
        fill="url(#svg_password11_linear)"
      />
      <path
        d="M67.996 65.483c.352-1.295.586-2.707.469-3.883 0-2.235-.352-5.53-3.986-6.824-3.752-1.294-7.855-.588-9.027 2.118-1.173 2.706-.938 6.824-1.173 8.941-.234 2.236-1.759 4.824-1.759 4.824l8.09 3.53s1.29-2.824 1.876-3.177c.586-.353.703 1.059 2.462.824.703-.118 1.524-1.412 2.227-3.06v-.117c.352 0 .82 0 .938-.118.586-.117.47-.352.352-.705-.235-.942-.352-1.765-.47-2.353z"
        fill="url(#svg_password12_linear)"
      />
      <path
        d="M79.134 111.719c0 .706 1.29 2.471 2.227 2.706.352.118.704.118.938 0 .47.236 1.876.353 1.29-.47.938.706 1.876.706 3.048.706.352 0 .352-.471-.469-.706-1.172-.353-1.993-1.059-2.58-2.118.118-.118.353.118.822.235.469.118.82-.117.82-.353 0-.117-.586-.353-.703-.47-.47-.353-1.055-.706-1.524-1.059-.587-.353-.938-.588-1.642-.588-.117 0-1.172 0-.938.47"
        fill="url(#svg_password13_linear)"
      />
      <path
        d="M61.08 85.836c1.289 2.706 4.806 11.883 5.392 12.942 2.11 4.118 13.13 13.177 13.13 13.177s1.876-.353 1.876-2.118c-3.165-2.235-9.027-11.53-10.2-12.706-.82-.706-4.337-15.177-4.337-15.177"
        fill="url(#svg_password14_linear)"
      />
      <path
        d="M64.01 75.6c-3.048-1.176-5.979-1.176-8.324-3.529-2.345 1.883 2.814 12.471 4.103 12.706 1.29.236 3.049 6.118 3.4 6.942 4.221-.118 5.745-2.47 5.745-2.47-1.641-5.177-1.993-12.472-4.924-13.648z"
        fill="url(#svg_password15_linear)"
      />
      <path
        d="M61.549 75.953c-.235-.823-.82-1.411-.938-2.235-.117-.824 0-1.765.469-2.47.234-.354.703-.707 1.055-.942 1.641-1.294 2.227-2.47 1.759-4.47-.235-.707-.352-1.295-.235-2 .117-.589.235-1.295.82-1.295.47 0 .704 0 .939.47.117.236-.118 2.236.586 1.06.586-1.06 1.172-1.177 1.172-3.53 0-.235-.117-1.412.117-1.647 1.173.824 1.407 2.235 1.524 3.294.587-.353.821-1.53.821-2 0-.941-.117-1.647-.469-2.47-1.407-3.177-3.048-3.765-4.338-4.236-1.172-.588-2.462-1.059-3.869-1.059-1.406 0-3.048.47-3.517 1.53-1.875-.471-3.048 1.176-3.4 1.764-.586.824-1.172 1.765-1.524 2.824-.468 1.53-.586 3.059-.938 4.706-.351 1.294-.82 2.588-1.758 3.412-.938.823-2.228.941-3.283 1.647-1.29.941-1.993 2.588-1.876 4.236.118 1.647.938 3.411-.117 4.823-.352.47-.703.824-1.055 1.412-.703 1.177-.586 2.706.117 3.765.704 1.176 1.993 2.47 3.283 2.823 1.29.354 2.227.589 3.517.471 2.345-.117 4.22 0 6.683-2.118 1.407-1.176.469-1.882 1.875-3.176.704-.706 1.76-1.06 2.345-2 .352-.706.47-1.647.235-2.589z"
        fill="url(#svg_password16_linear)"
      />
      <path
        opacity={0.5}
        d="M230.602 65.49l4.019-1.406c-.869-3.026-2.281-5.727-4.344-8.105l-3.367 2.486c-1.086-1.297-2.498-2.378-4.018-3.242l1.846-3.783c-1.303-.756-2.715-1.404-4.235-1.837-1.521-.432-3.041-.756-4.561-.864l-.652 4.214c-1.738-.108-3.475.108-5.104.54l-1.412-3.998c-3.041.865-5.756 2.27-8.145 4.323l2.389 3.35c-1.303 1.08-2.389 2.486-3.258 3.998l-3.801-1.837c-.76 1.297-1.412 2.702-1.846 4.215-.434 1.513-.76 3.026-.869 4.539l4.236.648c-.109 1.73.108 3.458.543 5.08l-4.019 1.404c.869 3.026 2.281 5.728 4.344 8.105l3.476-2.485c1.086 1.296 2.497 2.377 4.018 3.242l-1.846 3.89c1.303.757 2.715 1.405 4.235 1.837 1.52.433 3.041.757 4.561.865l.652-4.215c1.737.108 3.475-.108 5.104-.54l1.412 3.998c3.04-.864 5.755-2.27 8.145-4.322l-2.607-3.35c1.303-1.081 2.389-2.486 3.258-3.999l3.91 1.837c.76-1.297 1.412-2.702 1.846-4.214.434-1.513.76-3.026.869-4.54l-4.236-.648c.109-1.729-.108-3.458-.543-5.187zm-19.004 13.075c-4.887-1.513-7.711-6.7-6.191-11.563 1.521-4.863 6.734-7.673 11.621-6.16 4.886 1.513 7.71 6.7 6.19 11.563-1.521 4.863-6.733 7.673-11.62 6.16z"
        fill="#848484"
      />
      <path
        d="M231.254 183.822s.217 2.918.326 3.675c.108.756 2.497.648 2.715.216.217-.432.108-3.891.108-3.891"
        fill="url(#svg_password17_linear)"
      />
      <path
        d="M235.055 188.361c0 .757-.109 2.054-.109 2.054l-2.715.216-.217-.433s-.869.541-1.52.541l-2.281.108c-1.737.108-2.715 0-2.715-.324-.217-1.189 1.738-1.297 2.281-1.297 1.52-.108 3.149-.973 3.475-1.729.217-.541.109-.757.434-.649 0 0 .326 0 .543.325.326.54.869.864 1.521.648.326-.108.651-.216.869-.54-.218.108.325.324.434 1.08z"
        fill="#112B46"
      />
      <path
        d="M212.357 184.795s0 2.053.109 2.81c.109.756 2.498.54 2.715.108.217-.432-.109-3.891-.109-3.891"
        fill="url(#svg_password18_linear)"
      />
      <path
        d="M216.05 188.361c.109.757.109 2.27.109 2.27l-2.498.54-.326-.432s-.651.54-1.412.756c-.543.108-1.737.216-2.28.216-2.064-.108-3.367-.756-3.475-1.08-.326-1.189 2.389-1.081 2.932-1.081 1.52-.108 2.606-1.297 2.932-2.053.217-.541.109-.757.434-.649 0 0 .326 0 .543.325.326.54.978.864 1.521.648.326-.108.651-.216.977-.54-.108.216.435.324.543 1.08z"
        fill="#112B46"
      />
      <path
        d="M227.127 102.124c.217-.324.434 3.026 3.692 4.647 3.367 1.729 8.797.756 10.535.108 2.715-1.081 1.846-18.48-1.304-24.1-.76-1.296-3.583-1.944-5.43-2.269-.543 4.755 1.304 16.21 4.019 21.073-.218.865-11.512.541-11.512.541z"
        fill="url(#svg_password19_linear)"
      />
      <path
        d="M240.159 102.88c.76-1.08-.434-13.184-.326-15.021l-3.692 6.808c.651 2.81-2.064 7.673-5.43 7.565l-.435.648c1.955.432 9.123 1.081 9.883 0z"
        fill="url(#svg_password20_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.584 159.507c-.651-3.025-.977-7.456-1.194-11.671-.326-6.916-.326-13.508-.218-13.508v-4.755l.109-14.049 10.1.649s2.606 4.971 1.955 9.834c-.372 2.776-.886 4.037-1.299 5.051-.31.763-.563 1.385-.656 2.405-.664 7.928-1.356 13.359-1.847 17.209-.42 3.295-.692 5.432-.672 6.983l.021.015s1.955 3.567 2.172 6.917c.434 4.971-.109 21.289-.109 21.289s-2.606.54-3.909.108c0 0-2.988-17.495-4.295-26.045a1.561 1.561 0 01-.158-.432z"
        fill="url(#svg_password21_linear)"
      />
      <path
        d="M225.389 147.836c.76-2.269 2.172-6.7 3.258-9.186 1.629-3.458 2.607-5.511 2.607-5.511 5.755-6.052.651-13.292.651-13.292l-3.258 4.322c-.326.325-2.172 3.675-3.584 5.512v4.755c0-.108 0 6.484.326 13.4z"
        fill="url(#svg_password22_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.621 151.834c.76-7.456 4.018-28.961 7.059-35.662l12.923-1.296c3.826 11.42-.074 15.225-2.624 17.713-.563.549-1.06 1.034-1.394 1.523-.676.989-1.789 3.863-3.11 7.273-1.219 3.148-2.616 6.754-4.009 9.755l.06.046s-2.606 4.431-2.389 8.105c.217.865 1.52 6.592.651 9.834-1.629 6.808-2.063 16.967-2.063 16.967-2.281.432-3.91 0-3.91 0s-2.28-26.261-1.629-30.907c.106-.657.25-1.657.407-2.796 0-.189.009-.375.028-.555z"
        fill="url(#svg_password23_linear)"
      />
      <path
        d="M232.232 78.673s-.869-2.81-.435-4.755c.326-2.053 1.629-5.727 1.086-8.429-.434-2.702-3.909-5.08-7.602-4.647-3.692.324-4.778 3.89-5.321 5.944-.652 2.053-.543 5.187-.326 6.808.109 1.73 1.086 3.026 1.738 3.35.651.324 2.932-.108 3.366.324.435.433.76 3.026.76 3.026"
        fill="url(#svg_password24_linear)"
      />
      <path
        d="M233.86 81.05s-.109-1.62-.543-2.269c-.434-.648-4.235-.864-5.43-.864-1.086.108-4.018 1.297-4.453 1.513-.434.216.218 1.837.218 1.837l10.208-.216z"
        fill="url(#svg_password25_linear)"
      />
      <path
        d="M232.122 75.215s-4.995.108-6.081 0c-1.412-.216-3.258-1.297-3.367-3.026 1.195 0 1.303-2.593.652-3.566-.652-.973-1.521-.324-1.629.54-.109.865 0 .649-.218.757-.325.216-.76.108-.651-1.621.108-1.513.217-1.945.651-2.918.435-.973.761-.973 1.086-1.189-.434-.216-.977-.216-1.846-1.62-.977-1.622-.869-3.243.978-4.864 0 .433.543 1.297 1.737 1.621 2.172.649 6.299.108 8.254 1.189.869.432 1.629 1.621 1.194 2.377 0 0 1.629-.216 1.738 2.918.217 6.484-1.955 7.025-2.172 7.997-.109 1.08.109 1.189-.326 1.405z"
        fill="url(#svg_password26_linear)"
      />
      <path
        d="M217.68 116.173c.434.216.868.432 1.411.648 1.195.324 2.39.108 3.584 0 1.195-.108 2.498-.216 3.693-.324 6.516-.324 8.688 1.297 9.774-.324.217-.325.325-.757.217-1.297-.217-1.405-.652-1.945-.543-3.35.108-1.189.434-2.594.651-4.323 1.086-5.619 3.15-14.373 3.476-19.56.434-7.457-7.168-7.349-7.168-7.349-11.186-.648-15.313 2.486-15.964 7.24 0 .325-.109.757-.109 1.081-.325 4.972 1.086 15.13 1.846 21.074.435 1.729-1.737 6.051-.868 6.484z"
        fill="url(#svg_password27_linear)"
      />
      <path
        d="M217.353 116.605c.435.432 1.847.54 3.801.324-1.737.108-3.475-.972-3.149-1.837.434-.865 1.412-2.81 1.629-4.431.434-5.943.326-23.018.543-22.694-.217.54-1.086 1.297-3.149 1.73 0 4.97.868 13.724 1.194 19.343.217 5.512-2.715 6.052-.869 7.565z"
        fill="url(#svg_password28_linear)"
      />
      <path
        d="M194.222 73.918c0-.324.434-.108.543.108v.217c0-.433-.109-.865 0-.973 0-.324.325-.216.543 0v-.432c0-.325.434-.216.543.108 0 .108 0 .216.108.432v-.216c0-.324.326-.216.435 0 0 .108 0 .216.108.54 0 .325.109.649.217.865 0 .216.326 1.513.978 1.62.977.217.326-1.404.977-1.836.217-.108.435 1.188.435 1.837V77.7c0 .432-.326 1.08-.218 1.297 0 .216.326.972.326.972l-2.172.865s-.326-1.297-.543-1.621c-.76-.757-1.52-1.513-1.737-2.594-.435-.432-.435-1.08-.543-1.729v-.973z"
        fill="url(#svg_password29_linear)"
      />
      <path
        d="M196.502 80.51s.76 4.323 1.52 7.673c.543 2.594 1.304 5.403 2.607 7.889v.108c.76 1.621 2.498 3.89 3.366 4.323 4.236 1.945 13.684-9.294 15.965-8.537 4.561 1.188 7.927-11.672 7.927-11.672s-5.321.108-8.579.757c-3.475.756-12.598 12.103-13.032 12.103-.434 0-1.195-1.729-2.281-3.566-2.28-4.323-4.778-9.834-4.778-9.834l-2.063.648-.652.108z"
        fill="url(#svg_password30_linear)"
      />
      <path
        d="M219.634 68.083s-1.629 2.377-1.412 2.918c.217.54 1.738.432 1.738.432"
        fill="url(#svg_password31_linear)"
      />
      <defs>
        <linearGradient
          id="svg_password0_linear"
          x1={145}
          y1={26}
          x2={145}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="svg_password1_linear"
          x1={119.4}
          y1={113.849}
          x2={138.732}
          y2={183.504}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5D84C4" />
          <stop offset={0.433} stopColor="#5A78B8" />
          <stop offset={0.806} stopColor="#363D93" />
          <stop offset={1} stopColor="#272E85" />
        </linearGradient>
        <linearGradient
          id="svg_password2_linear"
          x1={125.668}
          y1={120.083}
          x2={140.587}
          y2={174.712}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password3_linear"
          x1={70.297}
          y1={111.444}
          x2={70.297}
          y2={86.126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password4_linear"
          x1={62.621}
          y1={84.187}
          x2={67.862}
          y2={113.087}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password5_linear"
          x1={62.621}
          y1={92.852}
          x2={66.954}
          y2={119.207}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password6_linear"
          x1={81.963}
          y1={108.722}
          x2={88.207}
          y2={107.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001462" />
          <stop offset={0.118} stopColor="#0F2880" />
          <stop offset={0.267} stopColor="#1E3C9E" />
          <stop offset={0.424} stopColor="#294BB6" />
          <stop offset={0.591} stopColor="#3256C7" />
          <stop offset={0.773} stopColor="#365DD1" />
          <stop offset={1} stopColor="#385FD4" />
        </linearGradient>
        <linearGradient
          id="svg_password7_linear"
          x1={47.865}
          y1={185.591}
          x2={47.865}
          y2={108.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password8_linear"
          x1={56.298}
          y1={186.537}
          x2={56.298}
          y2={107.377}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password9_linear"
          x1={40.016}
          y1={119.374}
          x2={63.697}
          y2={119.374}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password10_linear"
          x1={41.73}
          y1={78.1}
          x2={53.419}
          y2={108.676}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password11_linear"
          x1={59.5}
          y1={84.126}
          x2={63.577}
          y2={93.523}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password12_linear"
          x1={60.547}
          y1={73.484}
          x2={60.547}
          y2={55.286}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password13_linear"
          x1={83}
          y1={114.483}
          x2={83}
          y2={109.889}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password14_linear"
          x1={71.287}
          y1={110.899}
          x2={71.287}
          y2={83.658}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password15_linear"
          x1={54.435}
          y1={75.067}
          x2={61.285}
          y2={93.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password16_linear"
          x1={56.248}
          y1={52.526}
          x2={56.248}
          y2={85.978}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password17_linear"
          x1={232.843}
          y1={187.903}
          x2={232.843}
          y2={184.063}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password18_linear"
          x1={213.808}
          y1={187.955}
          x2={213.808}
          y2={184.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password19_linear"
          x1={226.386}
          y1={84.663}
          x2={244.068}
          y2={104.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#78A8FC" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password20_linear"
          x1={229.802}
          y1={90.24}
          x2={235.588}
          y2={104.711}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password21_linear"
          x1={225.116}
          y1={150.871}
          x2={237.451}
          y2={150.871}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password22_linear"
          x1={225.07}
          y1={133.855}
          x2={234}
          y2={133.855}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password23_linear"
          x1={210.085}
          y1={150.614}
          x2={232.224}
          y2={150.614}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password24_linear"
          x1={226.245}
          y1={79.608}
          x2={226.245}
          y2={61.899}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password25_linear"
          x1={222.791}
          y1={78.428}
          x2={223.085}
          y2={82.005}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password26_linear"
          x1={227.327}
          y1={57.762}
          x2={227.327}
          y2={75.322}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password27_linear"
          x1={215.565}
          y1={85.818}
          x2={229.437}
          y2={119.679}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password28_linear"
          x1={216.534}
          y1={92.396}
          x2={230.156}
          y2={100.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password29_linear"
          x1={196.722}
          y1={80.546}
          x2={196.722}
          y2={73.107}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password30_linear"
          x1={195.035}
          y1={82.952}
          x2={203.511}
          y2={107.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#78A8FC" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password31_linear"
          x1={219.082}
          y1={71.323}
          x2={219.082}
          y2={68.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={290}
      height={192}
      viewBox="0 0 290 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.657 189.915s-25.509-41.068 2.405-85.529c24.76-39.442 63.842-8.072 85.978-48.211 30.199-54.741 180.877-33.367 161.795 50.852-9.231 40.752 41.916 42.242 22.885 82.973l-273.063-.085z"
        fill="url(#svg_password0_linear)"
      />
      <g opacity={0.5}>
        <path
          d="M173 65.12h-34V44.841c0-1.567 1.304-2.841 2.909-2.841h12.837c.301 0 .602.098.903.294l3.109 2.547c.301.196.602.294.903.294h10.43c1.605 0 2.909 1.273 2.909 2.84V65.12z"
          fill="url(#svg_password1_linear)"
        />
        <path
          d="M168.648 62.4h-25.305c-.955 0-1.623-.801-1.623-1.702V47.782c0-1.001.764-1.702 1.623-1.702h25.305c.955 0 1.624.8 1.624 1.702v12.916c.095.901-.669 1.702-1.624 1.702z"
          fill="#fff"
        />
        <path
          d="M169.69 70.56h-27.38c-1.806 0-3.31-1.43-3.31-3.146V53.306c0-1.716 1.504-3.146 3.31-3.146h27.38c1.806 0 3.31 1.43 3.31 3.146V67.51c0 1.715-1.504 3.05-3.31 3.05z"
          fill="url(#svg_password2_linear)"
        />
        <path
          d="M161.346 56.96h-.757V55.6c0-2.234-2.056-4.08-4.543-4.08-2.488 0-4.544 1.846-4.544 4.08v1.36h-.757c-.541 0-.865.389-.865.777v6.412c0 1.36 1.19 2.331 2.596 2.331h7.031c1.514 0 2.596-1.069 2.596-2.331v-6.412c.108-.388-.324-.777-.757-.777zm-8.221-1.36c0-1.457 1.298-2.623 2.921-2.623 1.622 0 2.92 1.166 2.92 2.623v1.36h-5.841V55.6z"
          fill="#fff"
        />
        <path
          d="M156.68 61.018c0-.714-.635-1.338-1.36-1.338-.725 0-1.36.624-1.36 1.338 0 .535.363.98.816 1.16v2.407c0 .268.272.535.544.535s.544-.267.544-.535v-2.408c.544-.178.816-.624.816-1.16z"
          fill="url(#svg_password3_linear)"
        />
      </g>
      <path
        d="M222 190H86V85.85c0-4.36 3.472-7.85 7.811-7.85h120.291c4.34 0 7.811 3.49 7.811 7.85V190H222z"
        fill="url(#svg_password4_linear)"
      />
      <path
        d="M139.375 181.539H97.716a1.838 1.838 0 01-1.822-1.832v-19.015c0-.96.78-1.832 1.822-1.832h41.746c.955 0 1.823.785 1.823 1.832v19.015c-.087.96-.868 1.832-1.91 1.832zM175.914 99.196H96.935c-.52 0-1.041-.436-1.041-1.046v-2.007c0-.523.433-1.046 1.041-1.046h79.066c.521 0 1.041.436 1.041 1.046v2.007c-.087.61-.52 1.046-1.128 1.046zM175.914 107.57H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.523.433-1.047 1.041-1.047h79.066c.521 0 1.041.437 1.041 1.047v2.006c-.087.611-.52 1.047-1.128 1.047zM175.914 115.944H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.523.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.611-.52 1.047-1.128 1.047zM175.914 124.318H96.935c-.52 0-1.041-.436-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.093a1.13 1.13 0 01-1.128.96zM175.914 132.692H96.935c-.52 0-1.041-.437-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.61-.52 1.047-1.128 1.047zM175.914 141.066H96.935c-.52 0-1.041-.437-1.041-1.047v-2.006c0-.524.433-1.047 1.041-1.047h79.066c.521 0 1.041.436 1.041 1.047v2.006c-.087.61-.52 1.047-1.128 1.047zM177.477 176.48l.781 1.657 1.822.262-1.301 1.308.26 1.832-1.562-.872-1.649.872.347-1.832-1.302-1.308 1.823-.262.781-1.657zM185.461 176.48l.781 1.657 1.823.262-1.302 1.308.347 1.832-1.649-.872-1.649.872.347-1.832-1.302-1.308 1.823-.262.781-1.657zM193.445 176.48l.868 1.657 1.736.262-1.302 1.308.348 1.832-1.65-.872-1.562.872.261-1.832-1.302-1.308 1.822-.262.781-1.657zM201.518 176.48l.781 1.657 1.822.262-1.301 1.308.26 1.832-1.562-.872-1.649.872.347-1.832-1.302-1.308 1.736-.262.868-1.657zM209.502 176.48l.781 1.657 1.823.262-1.302 1.308.26 1.832-1.562-.872-1.649.872.347-1.832-1.302-1.308 1.823-.262.781-1.657z"
        fill="#E0E8EE"
      />
      <path
        d="M169.925 112.368c-9.633-3.228-14.754-9.159-14.754-9.159s-5.207 5.931-14.754 9.159c-8.766 2.878-17.966 2.529-17.966 2.529s0 31.489 10.762 44.137c10.068 11.863 21.871 13.957 21.871 13.957s11.804-2.094 21.871-13.957c10.762-12.648 10.762-44.137 10.762-44.137s-9.199.349-17.792-2.529z"
        fill="url(#svg_password5_linear)"
      />
      <path
        opacity={0.75}
        d="M154.043 168.019c-2.69-.698-10.935-3.577-18.225-12.125-6.597-7.85-8.853-25.819-9.461-36.199 3.819-.262 9.2-.873 14.408-2.617 5.988-2.006 10.414-4.885 13.278-7.153 2.865 2.268 7.291 5.234 13.279 7.153 5.208 1.744 10.589 2.442 14.407 2.617-.52 10.38-2.777 28.349-9.46 36.199-7.29 8.548-15.622 11.427-18.226 12.125z"
        fill="#83B4FF"
      />
      <path
        d="M154.044 166.361c-2.864-.785-10.328-3.576-17.011-11.514-6.075-7.152-8.332-23.464-8.939-33.757 3.732-.349 8.505-1.046 13.192-2.617 5.554-1.831 9.807-4.448 12.758-6.716a47.633 47.633 0 0012.758 6.716c4.687 1.571 9.46 2.268 13.192 2.617-.607 10.293-2.864 26.605-8.939 33.757-6.77 7.938-14.234 10.729-17.011 11.514z"
        fill="url(#svg_password6_linear)"
      />
      <path
        d="M159.511 132.517c0 3.053-2.43 5.495-5.468 5.495s-5.468-2.442-5.468-5.495 2.43-5.495 5.468-5.495c2.951 0 5.468 2.442 5.468 5.495z"
        fill="#fff"
      />
      <path
        d="M154.043 149.614a2.34 2.34 0 01-2.344-2.356v-12.647a2.34 2.34 0 012.344-2.356 2.34 2.34 0 012.343 2.356v12.735c-.087 1.221-1.128 2.268-2.343 2.268z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M168.798 112.368c-9.633-3.228-14.754-9.159-14.754-9.159v69.782s11.803-2.094 21.871-13.957c10.762-12.648 10.762-44.137 10.762-44.137s-9.287.349-17.879-2.529z"
        fill="#83B4FF"
      />
      <path
        d="M214.189 78H93.811C89.471 78 86 81.49 86 85.85v3.751h136v-3.75c0-4.362-3.472-7.851-7.811-7.851z"
        fill="url(#svg_password7_linear)"
      />
      <path
        d="M201.864 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221zM208.114 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221zM214.362 83.844c0 .698-.521 1.221-1.215 1.221-.695 0-1.215-.523-1.215-1.22 0-.699.52-1.222 1.215-1.222.607-.087 1.215.523 1.215 1.221z"
        fill="#fff"
      />
      <path
        d="M58.617 89.719c2.228 2.588 3.986 8.941 6.214 11.529 1.055 1.295 9.027 8.236 14.42 11.177.235-1.647 3.634-.588 2.462-1.647-3.751-3.412-10.2-6.824-12.427-13.06-1.407-4.117-6.8-13.176-6.8-13.176"
        fill="url(#svg_password8_linear)"
      />
      <path
        d="M104.222 107.719H64.479V83.365a3.408 3.408 0 013.4-3.411h15.006c.352 0 .703.117 1.055.352l3.634 3.06c.352.235.704.352 1.056.352h12.192c1.876 0 3.4 1.53 3.4 3.412v20.589z"
        fill="url(#svg_password9_linear)"
      />
      <path
        d="M100.12 104.307H69.052c-1.173 0-1.993-.941-1.993-2V87.13c0-1.176.938-2 1.993-2h31.068c1.172 0 1.993.941 1.993 2v15.177c.117 1.059-.821 2-1.993 2z"
        fill="#fff"
      />
      <path
        d="M100.353 114.19H68.347c-2.11 0-3.868-1.765-3.868-3.882V92.895c0-2.117 1.758-3.882 3.868-3.882h32.006c2.111 0 3.869 1.765 3.869 3.882v17.53c0 2.118-1.758 3.765-3.869 3.765z"
        fill="url(#svg_password10_linear)"
      />
      <path
        d="M90.154 97.954h-.82v-1.647c0-2.706-2.228-4.941-4.925-4.941-2.696 0-4.924 2.235-4.924 4.941v1.647h-.82c-.587 0-.938.47-.938.942v7.765c0 1.647 1.29 2.823 2.813 2.823h7.62c1.642 0 2.815-1.294 2.815-2.823v-7.766c.117-.47-.352-.94-.821-.94zm-8.91-1.647a3.158 3.158 0 013.165-3.176 3.158 3.158 0 013.166 3.176v1.647h-6.331v-1.647z"
        fill="#fff"
      />
      <path
        d="M86.051 101.954c0-.941-.82-1.764-1.758-1.764-.938 0-1.759.823-1.759 1.764 0 .706.47 1.295 1.055 1.53v3.177c0 .352.352.705.704.705.351 0 .703-.353.703-.705v-3.177c.704-.235 1.055-.824 1.055-1.53z"
        fill="url(#svg_password11_linear)"
      />
      <path
        d="M54.28 124.308l-4.221 10c1.875 3.059 2.93 6.707 3.165 10.471.117 1.883 0 3.883 0 5.765 0 .118 0 .236.117.471.587-2.824.938-5.177 1.055-6.706.47-4.353.352-11.177 0-19.883 0-.118 0-.118-.117-.118z"
        fill="#DB856E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.08 103.954l10.082 8.354.161 4.957c.403 12.265.71 21.62.191 26.926-.277 2.89-1.444 8.643-2.77 15.186-1.205 5.939-2.541 12.53-3.465 18.222l.021.005s-.133.725-.277 1.622c-.079.521-.154 1.033-.225 1.535-.11.861-.172 1.607-.084 1.785.234.588 1.172 2.235 2.11 2.706.481.301 1.301.634 2.002.918.666.27 1.224.496 1.28.611.118.235.235.706-.116.941-.821.589-2.11.942-3.049.824-1.584-.255-2.38-.75-3.056-1.17-.573-.356-1.06-.659-1.867-.713-.06 0-.14.023-.24.05-.303.083-.786.215-1.402-.05-.82-.353-1.172-1.176-1.172-1.882.093-.931.552-1.788.972-2.571.111-.207.22-.409.317-.606.47-.941.704-5.059.704-5.059l.107.028c.12-2.189.158-5.612.197-9.228.061-5.597.127-11.659.517-14.33.377-2.571 1.14-5.094 1.701-6.943.311-1.028.56-1.847.643-2.351.235-1.295-3.048-20.119-3.517-21.413-.187-.563-.467-1.145-.796-1.829-.496-1.028-1.102-2.286-1.666-4.054-1.875-6.117 2.697-12.471 2.697-12.471z"
        fill="url(#svg_password12_linear)"
      />
      <path
        d="M53.224 187.958c.235-.353.235-.942 0-1.059-.234-.236-1.29-.824-1.875-1.059-.586-.235-1.407-.471-1.173-.353.352.118-.234.823-.82.823-1.876-.117-3.166-1.294-4.221-1.529-1.524-.353-2.11-2.118-2.227-2.353-.235.235-.938 1.647-1.056 2.118-.117.353-.351 1.176-.234 1.529.117.235.234.824.234 1.53 0 0 .352.235 1.056.353.468.117 1.172 0 1.172 0l.117-.942s.352 0 .938.118c.469.118 1.407.588 1.407.588.234.236.352.706.703.824.704.353 1.407.47 2.345.588 2.11.118 3.283-.471 3.634-1.176z"
        fill="#112B46"
      />
      <path
        d="M67.996 187.252c-.234-.236-2.579-.589-3.634-1.059-1.055-.471-2.227-1.883-2.58-2.471-.234-.588-.234-3.529-.116-4.824.234-8.706 1.758-29.765 1.993-34.824.351-8.824-.704-17.883-4.104-32.002l-12.544-9.647s-3.517 6.589-2.345 12.824c.703 3.647 3.752 4.236 4.22 5.647.47 1.412 8.09 24.237 8.09 24.237s-1.524 4.941-1.524 10.353c-.117 4.588 1.993 18.589 2.345 23.883 0 1.059.234 3.53-.118 4.353-.351.941-1.29 2.118-1.29 3.294 0 .706.587 1.412 1.408 1.648.938.235 1.407-.236 1.641-.236 1.759-.117 2.227 1.177 5.276 1.177.938 0 2.344-.471 3.048-1.294.469-.471.469-.824.234-1.059z"
        fill="url(#svg_password13_linear)"
      />
      <path
        d="M68.231 188.311c.118-.353.118-.942-.117-1.059-.352-.118-1.407-.589-1.993-.824-.586-.117-1.524-.235-1.172-.117.351.117-.117.823-.704.941-1.876.235-3.4-.824-4.572-.824-1.641-.117-2.227-1.765-2.345-2-.234.235-1.055 1.765-1.172 2.235-.117.471-.235 1.177 0 1.53.117.235.352.824.352 1.412 0 0 .351.117 1.172.235.469 0 1.172-.118 1.172-.118v-.941s.47-.117.938-.117c.587 0 1.173.353 1.524.588.235.235.587.47.821.588.938.235 1.524.235 2.462.235 2.228-.353 3.4-1.058 3.634-1.764z"
        fill="#112B46"
      />
      <path
        d="M44.431 103.366c-3.165 3.294-5.275 8.118-4.103 13.412.352 1.412 2.11 5.177 2.345 6.589.82 3.177 2.227 7.765 2.462 11.53 4.924 2.941 14.42 1.882 18.523-1.059.118-4.471-.351-8-1.172-14.118-1.29-9.177-2.814-11.295-4.22-14.942-.118-.706-.118-2-.352-2.706"
        fill="url(#svg_password14_linear)"
      />
      <path
        d="M57.914 73.13c.82.235.469 2.118 1.29 2.353.586.235 1.641-.118 2.227.235 2.814 1.53 2.11 4.706 2.345 8.589 1.172 2.94 1.524 5.647.586 7.412-1.172 2.117-2.345 2.706-3.869 4.353-1.055 1.294-1.407 3.882-1.641 6.706 0 .471.352 1.294.234 1.765-.234.941-1.64 1.176-3.165 1.176-3.517.118-8.91-1.176-10.434-1.176-1.524 0-2.931-.706-2.697-1.53.118-.588.704-.823.938-2 .587-2.588.938-9.177 1.407-11.412 1.055-5.647 1.876-9.883 4.22-15.177"
        fill="url(#svg_password15_linear)"
      />
      <path
        d="M66.004 86.777c-.821-1.529-1.993-2.94-3.166-4.235l-3.048 1.412c1.407 2.706 1.641 6 2.345 8.941.703.118 1.758-.117 1.758-.117.352-.47.235-.236.587-.942.468-1.176 1.29-3.058 1.524-5.059z"
        fill="url(#svg_password16_linear)"
      />
      <path
        d="M67.996 65.483c.352-1.295.586-2.707.469-3.883 0-2.235-.352-5.53-3.986-6.824-3.752-1.294-7.855-.588-9.027 2.118-1.173 2.706-.938 6.824-1.173 8.941-.234 2.236-1.759 4.824-1.759 4.824l8.09 3.53s1.29-2.824 1.876-3.177c.586-.353.703 1.059 2.462.824.703-.118 1.524-1.412 2.227-3.06v-.117c.352 0 .82 0 .938-.118.586-.117.47-.352.352-.705-.235-.942-.352-1.765-.47-2.353z"
        fill="url(#svg_password17_linear)"
      />
      <path
        d="M79.134 111.719c0 .706 1.29 2.471 2.227 2.706.352.118.704.118.938 0 .47.236 1.876.353 1.29-.47.938.706 1.876.706 3.048.706.352 0 .352-.471-.469-.706-1.172-.353-1.993-1.059-2.58-2.118.118-.118.353.118.822.235.469.118.82-.117.82-.353 0-.117-.586-.353-.703-.47-.47-.353-1.055-.706-1.524-1.059-.587-.353-.938-.588-1.642-.588-.117 0-1.172 0-.938.47"
        fill="url(#svg_password18_linear)"
      />
      <path
        d="M61.08 85.836c1.289 2.706 4.806 11.883 5.392 12.942 2.11 4.118 13.13 13.177 13.13 13.177s1.876-.353 1.876-2.118c-3.165-2.235-9.027-11.53-10.2-12.706-.82-.706-4.337-15.177-4.337-15.177"
        fill="url(#svg_password19_linear)"
      />
      <path
        d="M64.01 75.6c-3.048-1.176-5.979-1.176-8.324-3.529-2.345 1.883 2.814 12.471 4.103 12.706 1.29.236 3.049 6.118 3.4 6.942 4.221-.118 5.745-2.47 5.745-2.47-1.641-5.177-1.993-12.472-4.924-13.648z"
        fill="url(#svg_password20_linear)"
      />
      <path
        d="M61.549 75.953c-.235-.823-.82-1.411-.938-2.235-.117-.824 0-1.765.469-2.47.234-.354.703-.707 1.055-.942 1.641-1.294 2.227-2.47 1.759-4.47-.235-.707-.352-1.295-.235-2 .117-.589.235-1.295.82-1.295.47 0 .704 0 .939.47.117.236-.118 2.236.586 1.06.586-1.06 1.172-1.177 1.172-3.53 0-.235-.117-1.412.117-1.647 1.173.824 1.407 2.235 1.524 3.294.587-.353.821-1.53.821-2 0-.941-.117-1.647-.469-2.47-1.407-3.177-3.048-3.765-4.338-4.236-1.172-.588-2.462-1.059-3.869-1.059-1.406 0-3.048.47-3.517 1.53-1.875-.471-3.048 1.176-3.4 1.764-.586.824-1.172 1.765-1.524 2.824-.468 1.53-.586 3.059-.938 4.706-.351 1.294-.82 2.588-1.758 3.412-.938.823-2.228.941-3.283 1.647-1.29.941-1.993 2.588-1.876 4.236.118 1.647.938 3.411-.117 4.823-.352.47-.703.824-1.055 1.412-.703 1.177-.586 2.706.117 3.765.704 1.176 1.993 2.47 3.283 2.823 1.29.354 2.227.589 3.517.471 2.345-.117 4.22 0 6.683-2.118 1.407-1.176.469-1.882 1.875-3.176.704-.706 1.76-1.06 2.345-2 .352-.706.47-1.647.235-2.589z"
        fill="url(#svg_password21_linear)"
      />
      <path
        d="M230.602 65.49l4.019-1.406c-.869-3.026-2.281-5.727-4.344-8.105l-3.367 2.486c-1.086-1.297-2.498-2.378-4.018-3.242l1.846-3.783c-1.303-.756-2.715-1.404-4.235-1.837-1.521-.432-3.041-.756-4.561-.864l-.652 4.214c-1.738-.108-3.475.108-5.104.54l-1.412-3.998c-3.041.865-5.756 2.27-8.145 4.323l2.389 3.35c-1.303 1.08-2.389 2.486-3.258 3.998l-3.801-1.837c-.76 1.297-1.412 2.702-1.846 4.215-.434 1.513-.76 3.026-.869 4.539l4.236.648c-.109 1.73.108 3.458.543 5.08l-4.019 1.404c.869 3.026 2.281 5.728 4.344 8.105l3.476-2.485c1.086 1.296 2.497 2.377 4.018 3.242l-1.846 3.89c1.303.757 2.715 1.405 4.235 1.837 1.52.433 3.041.757 4.561.865l.652-4.215c1.737.108 3.475-.108 5.104-.54l1.412 3.998c3.04-.864 5.755-2.27 8.145-4.322l-2.607-3.35c1.303-1.081 2.389-2.486 3.258-3.999l3.91 1.837c.76-1.297 1.412-2.702 1.846-4.214.434-1.513.76-3.026.869-4.54l-4.236-.648c.109-1.729-.108-3.458-.543-5.187zm-19.004 13.075c-4.887-1.513-7.711-6.7-6.191-11.563 1.521-4.863 6.734-7.673 11.621-6.16 4.886 1.513 7.71 6.7 6.19 11.563-1.521 4.863-6.733 7.673-11.62 6.16z"
        fill="#E0E8EE"
      />
      <path
        d="M231.254 183.822s.217 2.918.326 3.675c.108.756 2.497.648 2.715.216.217-.432.108-3.891.108-3.891"
        fill="url(#svg_password22_linear)"
      />
      <path
        d="M235.055 188.361c0 .757-.109 2.054-.109 2.054l-2.715.216-.217-.433s-.869.541-1.52.541l-2.281.108c-1.737.108-2.715 0-2.715-.324-.217-1.189 1.738-1.297 2.281-1.297 1.52-.108 3.149-.973 3.475-1.729.217-.541.109-.757.434-.649 0 0 .326 0 .543.325.326.54.869.864 1.521.648.326-.108.651-.216.869-.54-.218.108.325.324.434 1.08z"
        fill="#112B46"
      />
      <path
        d="M212.357 184.795s0 2.053.109 2.81c.109.756 2.498.54 2.715.108.217-.432-.109-3.891-.109-3.891"
        fill="url(#svg_password23_linear)"
      />
      <path
        d="M216.05 188.361c.109.757.109 2.27.109 2.27l-2.498.54-.326-.432s-.651.54-1.412.756c-.543.108-1.737.216-2.28.216-2.064-.108-3.367-.756-3.475-1.08-.326-1.189 2.389-1.081 2.932-1.081 1.52-.108 2.606-1.297 2.932-2.053.217-.541.109-.757.434-.649 0 0 .326 0 .543.325.326.54.978.864 1.521.648.326-.108.651-.216.977-.54-.108.216.435.324.543 1.08z"
        fill="#112B46"
      />
      <path
        d="M227.127 102.124c.217-.324.434 3.026 3.692 4.647 3.367 1.729 8.797.756 10.535.108 2.715-1.081 1.846-18.48-1.304-24.1-.76-1.296-3.583-1.944-5.43-2.269-.543 4.755 1.304 16.21 4.019 21.073-.218.865-11.512.541-11.512.541z"
        fill="url(#svg_password24_linear)"
      />
      <path
        d="M240.159 102.88c.76-1.08-.434-13.184-.326-15.021l-3.692 6.808c.651 2.81-2.064 7.673-5.43 7.565l-.435.648c1.955.432 9.123 1.081 9.883 0z"
        fill="url(#svg_password25_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.584 159.507c-.651-3.025-.977-7.456-1.194-11.671-.326-6.916-.326-13.508-.218-13.508v-4.755l.109-14.049 10.1.649s2.606 4.971 1.955 9.834c-.372 2.776-.886 4.037-1.299 5.051-.31.763-.563 1.385-.656 2.405-.664 7.928-1.356 13.359-1.847 17.209-.42 3.295-.692 5.432-.672 6.983l.021.015s1.955 3.567 2.172 6.917c.434 4.971-.109 21.289-.109 21.289s-2.606.54-3.909.108c0 0-2.988-17.495-4.295-26.045a1.561 1.561 0 01-.158-.432z"
        fill="url(#svg_password26_linear)"
      />
      <path
        d="M225.389 147.836c.76-2.269 2.172-6.7 3.258-9.186 1.629-3.458 2.607-5.511 2.607-5.511 5.755-6.052.651-13.292.651-13.292l-3.258 4.322c-.326.325-2.172 3.675-3.584 5.512v4.755c0-.108 0 6.484.326 13.4z"
        fill="url(#svg_password27_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.621 151.834c.76-7.456 4.018-28.961 7.059-35.662l12.923-1.296c3.826 11.42-.074 15.225-2.624 17.713-.563.549-1.06 1.034-1.394 1.523-.676.989-1.789 3.863-3.11 7.273-1.219 3.148-2.616 6.754-4.009 9.755l.06.046s-2.606 4.431-2.389 8.105c.217.865 1.52 6.592.651 9.834-1.629 6.808-2.063 16.967-2.063 16.967-2.281.432-3.91 0-3.91 0s-2.28-26.261-1.629-30.907c.106-.657.25-1.657.407-2.796 0-.189.009-.375.028-.555z"
        fill="url(#svg_password28_linear)"
      />
      <path
        d="M232.232 78.673s-.869-2.81-.435-4.755c.326-2.053 1.629-5.727 1.086-8.429-.434-2.702-3.909-5.08-7.602-4.647-3.692.324-4.778 3.89-5.321 5.944-.652 2.053-.543 5.187-.326 6.808.109 1.73 1.086 3.026 1.738 3.35.651.324 2.932-.108 3.366.324.435.433.76 3.026.76 3.026"
        fill="url(#svg_password29_linear)"
      />
      <path
        d="M233.86 81.05s-.109-1.62-.543-2.269c-.434-.648-4.235-.864-5.43-.864-1.086.108-4.018 1.297-4.453 1.513-.434.216.218 1.837.218 1.837l10.208-.216z"
        fill="url(#svg_password30_linear)"
      />
      <path
        d="M232.122 75.215s-4.995.108-6.081 0c-1.412-.216-3.258-1.297-3.367-3.026 1.195 0 1.303-2.593.652-3.566-.652-.973-1.521-.324-1.629.54-.109.865 0 .649-.218.757-.325.216-.76.108-.651-1.621.108-1.513.217-1.945.651-2.918.435-.973.761-.973 1.086-1.189-.434-.216-.977-.216-1.846-1.62-.977-1.622-.869-3.243.978-4.864 0 .433.543 1.297 1.737 1.621 2.172.649 6.299.108 8.254 1.189.869.432 1.629 1.621 1.194 2.377 0 0 1.629-.216 1.738 2.918.217 6.484-1.955 7.025-2.172 7.997-.109 1.08.109 1.189-.326 1.405z"
        fill="url(#svg_password31_linear)"
      />
      <path
        d="M217.68 116.173c.434.216.868.432 1.411.648 1.195.324 2.39.108 3.584 0 1.195-.108 2.498-.216 3.693-.324 6.516-.324 8.688 1.297 9.774-.324.217-.325.325-.757.217-1.297-.217-1.405-.652-1.945-.543-3.35.108-1.189.434-2.594.651-4.323 1.086-5.619 3.15-14.373 3.476-19.56.434-7.457-7.168-7.349-7.168-7.349-11.186-.648-15.313 2.486-15.964 7.24 0 .325-.109.757-.109 1.081-.325 4.972 1.086 15.13 1.846 21.074.435 1.729-1.737 6.051-.868 6.484z"
        fill="url(#svg_password32_linear)"
      />
      <path
        d="M217.353 116.605c.435.432 1.847.54 3.801.324-1.737.108-3.475-.972-3.149-1.837.434-.865 1.412-2.81 1.629-4.431.434-5.943.326-23.018.543-22.694-.217.54-1.086 1.297-3.149 1.73 0 4.97.868 13.724 1.194 19.343.217 5.512-2.715 6.052-.869 7.565z"
        fill="url(#svg_password33_linear)"
      />
      <path
        d="M194.222 73.918c0-.324.434-.108.543.108v.217c0-.433-.109-.865 0-.973 0-.324.325-.216.543 0v-.432c0-.325.434-.216.543.108 0 .108 0 .216.108.432v-.216c0-.324.326-.216.435 0 0 .108 0 .216.108.54 0 .325.109.649.217.865 0 .216.326 1.513.978 1.62.977.217.326-1.404.977-1.836.217-.108.435 1.188.435 1.837V77.7c0 .432-.326 1.08-.218 1.297 0 .216.326.972.326.972l-2.172.865s-.326-1.297-.543-1.621c-.76-.757-1.52-1.513-1.737-2.594-.435-.432-.435-1.08-.543-1.729v-.973z"
        fill="url(#svg_password34_linear)"
      />
      <path
        d="M196.502 80.51s.76 4.323 1.52 7.673c.543 2.594 1.304 5.403 2.607 7.889v.108c.76 1.621 2.498 3.89 3.366 4.323 4.236 1.945 13.684-9.294 15.965-8.537 4.561 1.188 7.927-11.672 7.927-11.672s-5.321.108-8.579.757c-3.475.756-12.598 12.103-13.032 12.103-.434 0-1.195-1.729-2.281-3.566-2.28-4.323-4.778-9.834-4.778-9.834l-2.063.648-.652.108z"
        fill="url(#svg_password35_linear)"
      />
      <path
        d="M219.634 68.083s-1.629 2.377-1.412 2.918c.217.54 1.738.432 1.738.432"
        fill="url(#svg_password36_linear)"
      />
      <defs>
        <linearGradient
          id="svg_password0_linear"
          x1={145}
          y1={26.319}
          x2={145}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="svg_password1_linear"
          x1={137.411}
          y1={45.525}
          x2={141.665}
          y2={69.63}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password2_linear"
          x1={137.411}
          y1={53.271}
          x2={140.745}
          y2={74.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password3_linear"
          x1={153.518}
          y1={66.147}
          x2={158.339}
          y2={65.177}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001462" />
          <stop offset={0.118} stopColor="#0F2880" />
          <stop offset={0.267} stopColor="#1E3C9E" />
          <stop offset={0.424} stopColor="#294BB6" />
          <stop offset={0.591} stopColor="#3256C7" />
          <stop offset={0.773} stopColor="#365DD1" />
          <stop offset={1} stopColor="#385FD4" />
        </linearGradient>
        <linearGradient
          id="svg_password4_linear"
          x1={153.995}
          y1={53.487}
          x2={153.995}
          y2={90.244}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E8EE" />
          <stop offset={1} stopColor="#F9FAFF" />
        </linearGradient>
        <linearGradient
          id="svg_password5_linear"
          x1={119.4}
          y1={113.849}
          x2={138.732}
          y2={183.504}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5D84C4" />
          <stop offset={0.433} stopColor="#5A78B8" />
          <stop offset={0.806} stopColor="#363D93" />
          <stop offset={1} stopColor="#272E85" />
        </linearGradient>
        <linearGradient
          id="svg_password6_linear"
          x1={125.668}
          y1={120.083}
          x2={140.587}
          y2={174.712}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password7_linear"
          x1={154}
          y1={78}
          x2={154}
          y2={89.601}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF2FF" />
          <stop offset={1} stopColor="#D7E9FC" />
        </linearGradient>
        <linearGradient
          id="svg_password8_linear"
          x1={70.297}
          y1={111.444}
          x2={70.297}
          y2={86.126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password9_linear"
          x1={62.621}
          y1={84.187}
          x2={67.862}
          y2={113.087}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password10_linear"
          x1={62.621}
          y1={92.852}
          x2={66.954}
          y2={119.207}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password11_linear"
          x1={81.963}
          y1={108.722}
          x2={88.207}
          y2={107.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001462" />
          <stop offset={0.118} stopColor="#0F2880" />
          <stop offset={0.267} stopColor="#1E3C9E" />
          <stop offset={0.424} stopColor="#294BB6" />
          <stop offset={0.591} stopColor="#3256C7" />
          <stop offset={0.773} stopColor="#365DD1" />
          <stop offset={1} stopColor="#385FD4" />
        </linearGradient>
        <linearGradient
          id="svg_password12_linear"
          x1={47.865}
          y1={185.591}
          x2={47.865}
          y2={108.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password13_linear"
          x1={56.298}
          y1={186.537}
          x2={56.298}
          y2={107.377}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password14_linear"
          x1={40.016}
          y1={119.374}
          x2={63.697}
          y2={119.374}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password15_linear"
          x1={41.73}
          y1={78.1}
          x2={53.419}
          y2={108.676}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password16_linear"
          x1={59.5}
          y1={84.126}
          x2={63.577}
          y2={93.523}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password17_linear"
          x1={60.547}
          y1={73.484}
          x2={60.547}
          y2={55.286}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password18_linear"
          x1={83}
          y1={114.483}
          x2={83}
          y2={109.889}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password19_linear"
          x1={71.287}
          y1={110.899}
          x2={71.287}
          y2={83.658}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password20_linear"
          x1={54.435}
          y1={75.067}
          x2={61.285}
          y2={93.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password21_linear"
          x1={56.248}
          y1={52.526}
          x2={56.248}
          y2={85.978}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password22_linear"
          x1={232.843}
          y1={187.903}
          x2={232.843}
          y2={184.063}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password23_linear"
          x1={213.808}
          y1={187.955}
          x2={213.808}
          y2={184.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password24_linear"
          x1={226.386}
          y1={84.663}
          x2={244.068}
          y2={104.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#78A8FC" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password25_linear"
          x1={229.802}
          y1={90.24}
          x2={235.588}
          y2={104.711}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password26_linear"
          x1={225.116}
          y1={150.871}
          x2={237.451}
          y2={150.871}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password27_linear"
          x1={225.07}
          y1={133.855}
          x2={234}
          y2={133.855}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password28_linear"
          x1={210.085}
          y1={150.614}
          x2={232.224}
          y2={150.614}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password29_linear"
          x1={226.245}
          y1={79.608}
          x2={226.245}
          y2={61.899}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password30_linear"
          x1={222.791}
          y1={78.428}
          x2={223.085}
          y2={82.005}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password31_linear"
          x1={227.327}
          y1={57.762}
          x2={227.327}
          y2={75.322}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="svg_password32_linear"
          x1={215.565}
          y1={85.818}
          x2={229.437}
          y2={119.679}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password33_linear"
          x1={216.534}
          y1={92.396}
          x2={230.156}
          y2={100.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password34_linear"
          x1={196.722}
          y1={80.546}
          x2={196.722}
          y2={73.107}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
        <linearGradient
          id="svg_password35_linear"
          x1={195.035}
          y1={82.952}
          x2={203.511}
          y2={107.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#78A8FC" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="svg_password36_linear"
          x1={219.082}
          y1={71.323}
          x2={219.082}
          y2={68.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C695" />
          <stop offset={1} stopColor="#FFE5C2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgPassword;
