import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import ConfirmPasswordModalComponent from './ConfirmPassword.modal';
import type { ConfirmPasswordModalProps } from './ConfirmPassword.modal';

const ConfirmPasswordModal = (
  props: ConfirmPasswordModalProps
): JSX.Element => (
  <ErrorBoundaryToast>
    <ConfirmPasswordModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default ConfirmPasswordModal;
